<template>
  <div class="statement-body">
    <div class="title">法律声明</div>
    <div class="nullHeight"></div>

    <p class="title_18">规则及伊登云商城网址访问条件</p>

    <p>以下规则(以下称“使用条款”)适用于所有访问伊登云商城网站的用户或浏览者，伊登软件有限公司及其关联公司(以下统称“伊登云”)保留根据国家法律法规及市场行情等变化修改这些规则的权利。访问本网站的权利由伊登云根据下列条款授予。如果您不同意下列任何条款，请停止使用本网址。对于违反这些规则的行为，伊登云有权采取法律和公平的补救措施。</p>

    <p class="title_18">伊登云商城网站内容</p>
    <p>伊登云服务网站所载的材料和信息，包括但不限于文本、图片、数据、观点、建议、网页或链接，虽然伊登云力图在网站上提供准确的材料和信息，但伊登云并不保证这些材料和内容的准确、完整、充分和可靠性，并且明确声明不对这些材料和内容的错误或遗漏承担责任，也不对这些材料和内容作出任何明示或默示的、包括但不限于有关所有权担保、没有侵犯第三方权利、质量和没有计算机病毒的保证。伊登云可以在没有任何通知或提示的情况下随时对网站上的内容进行修改，为了得到最新版本的信息，请您定时访问本网站。伊登云（含其关联公司）在本网站上所提及的非伊登云产品或服务仅仅是为了提供相关信息，并不构成对这些产品、服务的认可或推荐。伊登云并不就网址上提供的任何产品、服务或信息做出任何声明、保证或认可，所有销售的产品和服务应受伊登云的销售合同和条款的约束。 </p>

    <p class="title_18">软件内容</p>
    <p>本网站上所有源代码和二进制形式的软件、示例代码、API、SDK、相关文档和其他相关材料（统称“软件”）的所有权，包括所适用的知识产权归伊登云或其许可方所有。除非您跟据与伊登云或其关联公司的相关协议另行获得相应许可，本网站使用条款不就软件提供任何形式的许可，您不得对软件进行反向工程、反编译、反汇编、拆解、改编、植入或其他派生操作，不得以任何方式研究伊登云品内部实现、获取产品源代码、窃取知识产权等，也不得披露任何软件性能测试的结果。</p>

    <p class="title_18">您对本网站的使用</p>
    <p>您不得出于任何非法或本使用条款禁止的目的使用本网站和/或其包含的任何内容，不得将本网站和/或其包含的任何内容用于任何非法用途，也不得唆使任何非法活动或其他侵犯伊登云或他人权利的活动。</p>

    <p>您不得以任何非法方式，在未经授权的情况下访问本网站及其任何部分，或接受通过本网站提供的任何服务，或连接到伊登云服务器的任何其他系统或网络。</p>

    <p>您同意不会采取任何会对本网站及其相关的架构、系统、网络带来不合理或不成比例的高负载的行为。</p>

    <p>您不得通过本网站未提供的方式获取或试图获取本网站的任何内容，亦不得使用任何自动或手动的流程、抓取设备、程序、算法或方法，来访问、获取、拷贝或监控本网站的任何组成部分或内容。</p>

    <p>您不得以扫描等方式测试本网站和/或其网络是否存在漏洞，亦不得破坏网站及其服务所采用的安全或身份验证措施。您不得反向搜索、跟踪或试图跟踪有关伊登云及本网站的任何其他用户或访客的任何信息；亦不得以任何方式滥用或泄露网站内的任何服务或信息。</p>

    <p>您不得以任何方式（包括但不限于使用任何设备、软件或程序）干扰或试图干扰本网站的正常运作及其他人对本网站的正常使用。</p>

    <p>您同意在使用本网站及其内容时，遵守国家法律法规、社会公共道德。您不得利用本网站及其内容从事制作、查阅、复制和传播任何违法、侵犯他人权益等扰乱社会秩序、破坏社会稳定的行为，亦不得利用本网站及其内容从事任何危害或试图危害计算机系统及网络安全的活动。</p>

    <p class="title_18">商标</p>
    <p>伊登云网站上使用和显示的所有商标、标志皆属伊登云或其许可人所有。伊登云网站所载的任何内容不应被视作未经伊登云或其他方书面许可，以暗示、不反对或其他形式授予使用前述任何商标、标志的许可或权利。未经事先书面许可，任何人不得以任何方式使用伊登云名称及伊登云的商标、标记。</p>

    <p class="title_18">隐私</p>
    <p>伊登云的<a href="/statement/privacyStatement" target="_blank">隐私声明</a>适用于本网站的使用。使用本网站，即表示您确认并同意伊登云可以根据此隐私声明使用此类信息。</p>


    <p class="title_18">著作权说明</p>
    <p>本网站所载的所有材料或内容受版权法的保护，所有版权由伊登云或其许可方拥有，但注明引用其他方的内容除外。未经伊登云或其他方事先书面许可，任何人不得将本网站上的任何内容以任何方式进行复制、修改、传播、经销、翻印、播放、拆解、反向工程、反编译、以超级链路连接或传送、以镜像法载入其他服务器上、存储于信息检索系统或者其他任何的使用。</p>

    <!-- <p class="title_18">提供的产品或服务</p>
     <p>由于互联网的国际性或无国界性，因此通过本网站所提供信息的亦具有国际性，其中可能包含对未在您所在国家/地区发布的产品或服务的引用，所以不是所有的在本网站上所提到的产品或服务在您的国家或地区都提供，请联系当地的销售代表或经销商了解在您的国家或地区所提供的产品或服务。</p>
 -->
    <p class="title_18">第三方链接</p>
    <p>本网站可能保留有与第三方网站或网址的链接，访问这些链接将由用户自己作出决定，伊登云并不保证这些链接上所提供的任何信息、数据、观点、图片、陈述或建议的准确性、完整性、充分性和可靠性。伊登云提供这些链接仅仅在于提供方便，并不表示伊登云对这些信息的认可和推荐，也不是用于宣传或广告目的。</p>


    <p class="title_18">免责声明</p>
    <p>伊登云不应对间接、附带、特殊或任何形式的惩罚性赔偿承担任何责任，也不应对任何利润、收入、数据、数据使用的损失承担任何责任。除非伊登云在销售合同中另行书面同意，在适用法允许的范围内，伊登云不对本网站的“资料”承担任何责任，不论该责任因何原因引起或者基于何种侵权理论。</p>

    <p class="title_18">适用法律与争议解决</p>
    <p>您理解并同意，与您访问或使用本网站相关的所有事项，适用中华人民共和国法律解释。您理解并同意，因本声明产生的任何争议，任何一方均可向中国广东省深圳市福田区人民法院提起诉讼。</p>

    <div class="nullHeight"></div>
  </div>
</template>

<script>
export default {
  name: "lawStatement"
}
</script>

<style scoped>
.statement-body {
  display: block;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 20px auto;
}
.statement-body p{
  font-size: 16px !important;
}
.title {
  font-family: 微软雅黑, "Microsoft YaHei";
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

a {
  color: #1B79C8;
  text-decoration: inherit;
}

a:hover {
  color: #F56E65;
}

.nullHeight {
  height: 20px;
  width: 100%;
}

.title_18 {
  font-size: 18px;
  font-weight: 700;
  padding: 0px;
  font-family: 微软雅黑, " Microsoft YaHei";
  background-image: initial !important;
  background-position: 0px 0px !important;
}

.title_16 {
  font-size: 16px;
  font-weight: 700;
  padding: 0px;
  font-family: 微软雅黑, " Microsoft YaHei";
  background-image: initial !important;
  background-position: 0px 0px !important;
}

.title_14 {
  font-size: 14px;
  font-weight: 700;
  padding: 0px;
  font-family: 微软雅黑, " Microsoft YaHei";
  background-image: initial !important;
  background-position: 0px 0px !important;
}

</style>
