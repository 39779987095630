import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './routers'
import store from './store/store.js'
import elementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import urlUtils from './utils/url-utils'
import VueWechatTitle from 'vue-wechat-title'
//import header from '@/views/common/header.js'
import 'assets/css/index.css'
import i18n from './i18n/i18n'
import moment from "moment"; // 1.引入
import storage from '@/utils/storage.js'
Vue.prototype.$moment = moment // 2.挂载到原型
Vue.use(VueWechatTitle)
Vue.prototype.buyerMsg = (msg, type) => {
    let msgFunc = 'success';
    if(type) {
        msgFunc = type;
    }
    elementUI.Message[msgFunc]({
        message: msg,
        offset: 80
    })
}
Vue.use(storage)
Vue.use(elementUI)
Vue.prototype.$urlUtils = urlUtils
Vue.config.productionTip = false
Vue.use(Vuex)
// Vue.use(header);

router.beforeEach((to, from, next) => {
    document.title = to.meta.title +' - 伊登SaaS商城' ;
    let token = localStorage.getItem('accessToken') // token key
    console.log('token')
    if(!token){
        console.log('退出登录')
        store.commit('logout');
    }
    // 需要守卫的路由集合
    let routeList = ['/orderList','/membership','/user','/goodsUpgrade','/goodsRenewal','/goodsDescription']
    // 如果已经登录，那我不干涉你，让你随便访问 todo 目前关闭的登录token验证
    if (token) {
        next()
    } else {
        if (routeList.includes(to.path)) {
            // 如果没有登录，但你访问其他需要登录的页面，那我就让你跳到登录页面去
            next({path: '/login'})
        } else {
            // 如果没有登录，但你访问的login，那就不干涉你，让你访问
            next()
        }
    }
})
router.afterEach(() => {
    window.scrollTo(0,0);
    // document.querySelector("body").setAttribute("style", "overflow: auto !important;")
});
new Vue({
    render: h => h(App),
    router,
    store,
    i18n,
}).$mount('#app')
