<template>
    <div class="statement-body">
    <div class="title">隐私政策</div>
    <div class="nullHeight"></div>

    <p>伊登云商城（以下或简称“我们”）深知您的隐私对您的重要性，并充分尊重您的隐私。在同意向我们提交您的个人数据之前，请您仔细阅读并理解本《隐私政策声明》（以下简称“本声明”）。本声明适用于显示或链接至本声明的伊登云商城网站（以下简称“本网站”）和产品、服务。本声明不适用于您选择在伊登云商城中处理、存储或托管的内容数据。未经您的同意，我们不会出于任何目的访问或处理您的内容数据，除非适用的法律法规要求。</p>

    <p>内容数据是指您使用我们产品或服务时，您进行处理、存储或交由与您的账户关联的我们的产品或服务托管的软件，以及通过上述内容数据得出的任何计算结果。这不包括您在创建或管理账户时向我们提供的账户信息。</p>

    <p>本声明描述了我们如何收集、使用和披露您的个人数据，以及数据处理的法律依据和安全措施。它还表明在我们处理您的个人数据时，您控制个人数据的权利，以及您在向我们提供个人数据之前需要了解的其他相关详细信息。但是，本声明可能无法涉及所有数据处理场景。我们可能会在收集前通过补充声明或通知，告知您特定产品或服务的数据收集。</p>

    <p>
        我们制定本声明以帮助您理解：<br>
        &nbsp;&nbsp;&nbsp;&nbsp;伊登云商城如何收集您的个人数据<br>
        &nbsp;&nbsp;&nbsp;&nbsp;伊登云商城如何处理您的个人数据<br>
        &nbsp;&nbsp;&nbsp;&nbsp;伊登云商城如何使用Cookies<br>
        &nbsp;&nbsp;&nbsp;&nbsp;伊登云商城如何保护您的个人数据<br>
        &nbsp;&nbsp;&nbsp;&nbsp;第三方服务提供商及服务<br>
        &nbsp;&nbsp;&nbsp;&nbsp;您的个人数据如何进行跨境转移<br>
        &nbsp;&nbsp;&nbsp;&nbsp;如何更新本声明<br>
        &nbsp;&nbsp;&nbsp;&nbsp;如何联系伊登云商城<br>
    </p>

    <p class="title_bold">
        1.伊登云商城如何收集您的个人数据<br>
        1.1 个人数据
    </p>
    <p>
        个人数据是指单独使用或结合其他信息使用时能够确定个人身份的信息。我们在您与我们的互动过程中出于不同的目的收集您的个人数据，例如向您提供我们的产品或服务以及维护我们产品或服务的最佳运营状态。您与我们的互动可能包括：在本网站注册账户，订购我们的产品或服务，向我们完成付款，在处理问题时联系我们，浏览我们的网站以及配置我们网站的设置或您的账户设置。<br>
        根据您使用本网站以及我们提供的产品或服务，我们收集的个人数据主要包括以下内容：<br>
        a)&nbsp;&nbsp;提交给我们的个人数据，用于在本网站注册用户账户，例如用户名、用户ID、手机号码和邮箱。<br>
        b)&nbsp;&nbsp;当您联系我们，使用我们的产品或服务，或获得我们支持或帮助时提供的个人数据，例如地址，姓名，身份证号码，电子邮箱，发票信息，税务信息。<br>
        c)&nbsp;&nbsp;当您访问和使用本网站或本网站提供的服务时，我们获取并自动记录的个人数据，例如您的历史浏览记录、访问日期和时间，您使用的浏览器信息（包括但不限于字体和类型等），网站上的操作，硬件软件功能以及网络链接信息（IP地址、端口、网络协议等）。<br>
        我们的许多产品或服务都需要个人数据才能运营。如果您选择不提供运营所需的数据，则无法使用该产品或服务。例如，如果您未在我们的网站上注册账户并提交您的个人数据，则无法购买我们的产品或服务。在其他一些情况下，如果提供数据是可选的，并且您选择不提交个人数据，您可能无法使用产品或服务的某些功能，但这不会影响您使用我们提供的其他产品或服务。
    </p>
    <p class="title_bold">
        1.2非个人数据
    </p>
    <p>非个人数据是指不能用于识别个人的数据。例如，我们可能会收集汇总的统计数据，例如网站的访问数量。我们收集这些数据以了解人们如何使用我们的网站、产品或服务。借此，我们能够改进产品或服务，以更好地满足用户的需求。我们可能会自行决定出于其他目的收集、使用、处理、传输或披露非个人数据。<br>我们努力将您的个人数据和非个人数据隔离，并单独使用。如果非个人数据掺杂了个人数据，则将其视为个人数据。</p>
    <p class="title_bold">
        2.伊登云商城如何处理您的个人数据
    </p>
    <p>
        除了以下情形，我们不会将您的个人数据用于其他目的：<br>
        (a)&nbsp;&nbsp;创建账户<br>
        (b)&nbsp;&nbsp;实现您的交易或服务请求，包括履行订单；交付、激活或验证产品服务；应您的要求进行变更或提供您请求的信息（例如产品或服务的营销资料、白皮书）；以及提供技术支持。<br>
        (c)&nbsp;&nbsp;与您联系；向您发送您可能感兴趣的产品和服务的信息；邀请您参加伊登云商城活动（包括促销活动）、市场调研或满意度调查；或向您发送营销信息。<br>
        (d)&nbsp;&nbsp;向您发送重要通知。<br>
        (e)&nbsp;&nbsp;为您提供个性化用户体验和个性化内容。<br>
        (f)&nbsp;&nbsp;开展内部审计、数据分析和研究，改善我们的产品和服务。<br>
        (g)&nbsp;&nbsp;分析业务运营效率并衡量市场份额。<br>
        (h)&nbsp;&nbsp;在您选择向我们发送错误详情的情况下排查错误。<br>
        (i)&nbsp;&nbsp;保护我们产品、服务和用户的安全，执行与改善我们的防损和反欺诈计划。<br>
        (j)&nbsp;&nbsp;遵从和执行适用的法律要求或相关的行业标准。<br>
        我们不会向第三方披露个人数据，除了以下情形：<br>
        (a)&nbsp;&nbsp;根据适用法律法规，披露是必须的。<br>
        (b)&nbsp;&nbsp;适当情形下，我们可能会在最有限的范围内披露您的个人数据，以保护伊登云商城、伊登云商城用户和公众的权利或财产。例如，当我们认为披露对于防止或保护网络威胁、欺诈、人身伤害或财务损失是必要的或适当的，或当它与涉嫌或实际非法活动的调查有关的。<br>
        (c)&nbsp;&nbsp;我们可能会将您的个人数据向我们的关联公司、分公司披露，以供它们为您提供交易支持、服务支持或安全支持。在该情形下，我们确保这些公司应当采用与声明同等的个人数据保护标准，并且不应单将个人数据用于其他目的。<br>
        (d)&nbsp;&nbsp;我们可能会根据您的同意和指示将您的个人数据披露给第三方。<br>
        我们仅在适用法律法规允许的范围内并根据本声明披露您的个人数据。除非您另行同意或本声明特别规定，我们不会向任何不相关的第三方提供、出售或出租您的个人数据。
    </p>
    <p class="title_bold">3.伊登云商城如何使用Cookies</p>
    <p>
        为确保网站正常运转，我们有时会在计算机或移动设备上存储Cookie的小数据文件。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储用户偏好等数据。<br>
        我们启用Cookie的目的与大多数网站或互联网服务提供商启用Cookie的目的一样，即改善用户体验。具体而言，有以下目的：<br>
        a)&nbsp;&nbsp;借助于Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览器偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。<br>
        b)&nbsp;&nbsp;我们可能会收集伊登云商城广告的数据，例如在伊登云商城网站上广告弹出的次数，以及在其他网站上广告被点击的情况。<br>
        c)&nbsp;&nbsp;认证。当您访问伊登云商城时，我们会为您生成一个单独的ID，以标识您的身份。如果网站不使用具有功能的Cookie，那么在用户每一次打开网页时，该网站都会将其视为新访客。例如，如果您登陆伊登云商城网站后转到另一个网页，伊登云商城网站就不会识别出您，您会被视为新访客。<br>
        d)&nbsp;&nbsp;统计分析。例如我们使用Cookie以统计用户访问数量，以及您的访问来源。<br>
        e)&nbsp;&nbsp;安全。我们使用Cookie保障网站运作安全。<br>
        其他第三方会将Cookie设置在我们的网站上。它们使用Cookie接收IP地址、您浏览器配置相关的信息和访问信息，但不收集您的个人身份信息。它们使用Cookie以了解您和网站的互动情况。您可以通过访问它们的网站以了解这些第三方如何适用Cookie。<br>
        您可根据自己的偏好管理或删除Cookie。有关详情，请参见<a href="https://www.aboutcookies.org/" target="_blank">AboutCookies.org</a>。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有组织Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。如需详细了解如果更改浏览器设置，请访问以下链接：<br>
        <a href="https://support.microsoft.com/en-us/help/260971/description-of-cookies" target="_blank">Internet Explorer</a><br>
        <a href="https://support.google.com/chrome/answer/95647?hl=en-GB&p=cpn_cookies" target="_blank">Google Chrome</a><br>
        <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies" target="_blank">Mozilla Firefox</a><br>
        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">Safari</a><br>
        <a href="https://www.opera.com/o/ie-simple" target="_blank">Opera</a><br>
    </p>
    <p class="title_bold">4.伊登云商城如何保护您的个人数据</p>
    <p>
        我们重视个人数据的安全。我们采用适当的物理、管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可以访问个人数据；以及我们会举办安全和隐私保护培训，加强员工对于保护个人数据重要性的认识。我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。<br>
        我们将会在达成本声明所述目的所需的期限内保留您的个人数据，除非按照法律要求或您的要求需要延长保留期。基于不同的场景、产品以及服务，数据的存储期可能会有所不同，我们用于确定存留期的标准包括：完成该业务目的需要留存个人数据的时间，包括提供产品和服务，依据法律要求维护相应的交易及业务记录，保证系统、产品和服务的安全，应对可能的用户查询或投诉、问题定位等；用户是否同意更长的留存期间；法律、合同等是否有保留数据的特殊要求等。只要您的账户是为您提供服务之必须，我们都将保留您的账户信息。您也可以选择注销您的账号，在您注销账号后，我们会停止基于该账号提供产品和服务，并在无特殊法律要求的情况下，删除您相应的个人数据。<br>
        尽管我们保护您的个人数据，但您有责任妥善保管您的个人数据，包括账户和密码。<br>
        如果您发现其他人未经您的许可使用您的账户或密码登录本网站，请及时通知我们。您有权要求我们暂停未经授权对您的账户或密码的试用。<br>
        如发生个人数据泄露等安全事件，我们将依照适用的法律法规的要求，以网站公告或邮件的方式，向您或主管机关告知数据泄露事件的相关信息。如果因完全归因于伊登云商城的原因造成您的个人数据泄露，在伊登云商城确认相关数据泄露事件及原因后，我们将尽合理努力不迟延地向您发送数据泄露的通知。
    </p>
    <p class="title_bold">5.如何访问或控制您的个人数据</p>
    <p>
        您应确保提交的所有个人数据都准确无误。我们会尽力维护个人数据的准确和完整，并及时更新这些数据。<br>
        根据适用的法律法规，您可能（1）有权访问我们持有您的任何个人数据；（2）要求我们更新或更正您的个人数据；（3）要求我们删除您的个人数据；（4）拒绝或限制我们使用您的个人数据；以及（5）获取个人数据副本。如果您需要我们协助行驶上述权利，请通过“9.如何联系伊登云商城”联系我们。一般情形下，我们会在一个月内予以响应，除非适用的法律法规要求。结合您请求的复杂度，该时间可能延迟两个月，但我们会在收到请求的一个月内告知延迟原因。请注意在某些情况下，您的请求将会被驳回，包括但不限于您的身份无法被验证，或您提出的要求超出权利范围，或其他法律法规规定的情形。我们将通过本声明第9点的邮箱书面告知您提出的请求被驳回、被驳回的原因及相关依据。如果您发现任何违反本声明的行为，您也可以向有管辖权数据保护机构或其他官员提出疑虑或投诉。<br>
        以下是您控制我们收集的个人数据的一些说明：<br>
        a)&nbsp;&nbsp;账户信息：如果您要添加或更新与您的账户相关的个人数据，请访问网站并按照以下步骤操作：登录>买家中心。<br>
        b)&nbsp;&nbsp;Cookies：关于Cookies的管理，请参阅3.伊登云商城如何使用Cookies。<br>
    </p>
    <p class="title_bold">6.第三方服务提供商及服务</p>
    <p>
        为提升您的服务体验，您可能会收到来自于伊登云商城之外的第三方提供的内容或网络链接。我们对此类第三方无控制权。您可选择是否访问或使用第三方提供的链接、内容、产品或服务。<br>
        我们无法控制第三方的隐私和数据保护政策，此类第三方不受到本声明的约束。在向第三方提交个人数据之前，请参见第三方的<a href="http://mall.edensoft.com.cn/statement/privacyStatement" target="_blank">隐私保护政策</a>。
    </p>
    <p class="title_bold">7.您的个人数据如何跨境转移</p>
    <p>
        为了给您提供服务，我们会收集您的个人数据可能存储在伊登云商城及其关联公司、服务提供商所在国家/地区。在此类情况下，我们会采取措施确保收集的个人数据依据本声明和数据所在地适用法律的要求进行处理。
    </p>
    <p class="title_bold">8.如何更新本声明</p>
    <p>
        我们可能会根据我们的服务或数据处理的变化不时更新或修改本声明。如果我们更新本声明，我们会将最新版隐私政策声明发布在伊登云商城网站上，并且发布时立即生效。建议您定期查看本声明以了解任何更改。如果我们对本声明做出任何重大更改（收集的个人数据范围和使用目的变更），我们将会通过适当渠道通知您，并获取您的同意。
    </p>
    <p class="title_bold">9.如何联系伊登云商城</p>
    <p>
        如果您想联系我们，请通过以下方式与我们联系：<br>
        联系方式：reception@edensoft.com.cn<br>
        最近更新时间：2020年10月<br>
    </p>
    <div class="nullHeight"></div>
    </div>
</template>

<script>
    export default {
        name: "privacyStatement"
    }
</script>

<style scoped>
    .statement-body {
        display: block;
        width: 100%;
        max-width: 1200px;
        padding: 0 20px;
        margin: 20px auto;
    }
    .statement-body p{
        font-size: 16px !important;
    }

    .title {
        font-family: 微软雅黑, "Microsoft YaHei";
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .nullHeight {
        height: 20px;
        width: 100%;
    }

    .title_18 {
        font-size: 18px;
        font-weight: 700;
        padding: 0px;
        font-family: 微软雅黑, " Microsoft YaHei";
        background-image: initial !important;
        background-position: 0px 0px !important;
    }

    .title_16 {
        font-size: 16px;
        font-weight: 700;
        padding: 0px;
        font-family: 微软雅黑, " Microsoft YaHei";
        background-image: initial !important;
        background-position: 0px 0px !important;
    }

    .title_14 {
        font-size: 14px;
        font-weight: 700;
        padding: 0px;
        font-family: 微软雅黑, " Microsoft YaHei";
        background-image: initial !important;
        background-position: 0px 0px !important;
    }

    .title_12 {
        font-size: 14px;
        font-weight: 700;
    }

    .title_bold {
        font-weight: bold;
    }

    a {
        color: #0090ff;
    }

</style>