const common = {
    state:{
        checkIndex: 1
    },
    mutations:{
        setCheckIndex(state,info){
            state.checkIndex = info
        }
    },

};
export default common