<template>
  <el-row>
    <el-col class="footer">
      <el-col class="footer-container">
        <el-col class="eden-biz">
          <el-col class="biz-item">
            <el-col class="item-icon">
              <img src="@/img/common/footer/icon/icon_hour_service.svg">
            </el-col>
            <el-col class="item-title">7*24服务</el-col>
          </el-col>
          <el-col class="biz-item">
            <el-col class="item-icon">
              <img src="@/img/common/footer/icon/icon_beian.svg">
            </el-col>
            <el-col class="item-title">免费备案</el-col>
          </el-col>
          <el-col class="biz-item">
            <el-col class="item-icon">
              <img src="@/img/common/footer/icon/icon_professional_service.svg">
            </el-col>
            <el-col class="item-title">专业服务</el-col>
          </el-col>
          <el-col class="biz-item">
            <el-col class="item-icon">
              <img src="@/img/common/footer/icon/icon_worry_free.svg">
            </el-col>
            <el-col class="item-title">退订无忧</el-col>
          </el-col>
          <el-col class="biz-item">
            <el-col class="item-icon">
              <img src="@/img/common/footer/icon/icon_service_provider.svg">
            </el-col>
            <el-col class="item-title">优质服务商</el-col>
          </el-col>
        </el-col>
        <el-col class="horizontal-line"></el-col>
        <el-col class="about-eden">
          <el-col class="contact">
            <el-col class="contact-title">上云服务热线</el-col>
            <el-col class="contact-title phone">400 830 0095</el-col>
            <el-col class="contact-wechat">
              <img src="@/img/common/footer/wechat.png">
            </el-col>
            <el-col class="contact-title wechat">微信公众号</el-col>
          </el-col>
          <el-col class="vertical-line"></el-col>
          <el-col class="eden-product">
            <el-col class="about">
              <el-col class="item title">关于伊登</el-col>
              <el-col class="item footer-item" @click.native="toDirect('https://www.edensoft.com.cn/')">了解伊登</el-col>
              <el-col class="item footer-item" @click.native="toDirect('https://www.edensoft.com.cn/corporate')">公司治理</el-col>
              <el-col class="item footer-item" @click.native="toDirect('https://www.edensoft.com.cn/relation')">投资者关系</el-col>
            </el-col>
            <el-col class="product">
<!--              <el-col class="item title">热门产品</el-col>-->
                <el-col class="item title">热门服务</el-col>
              <el-col class="item footer-item" @click.native="toDirect('https://www.edensoft.com.cn/product/itSupport')">IT基础设施</el-col>
              <el-col class="item footer-item" @click.native="toDirect('https://www.edensoft.com.cn/product/impleSupportOverview')">IT实施与支持</el-col>
              <el-col class="item footer-item" @click.native="toDirect('https://www.edensoft.com.cn/product/cloudService')">云服务</el-col>
            </el-col>
            <el-col class="account">
              <el-col class="item title">账户管理</el-col>
              <el-col class="item footer-item" @click.native="goPage('/buyer/cloudOrders')">合同申请</el-col>
              <el-col class="item footer-item" @click.native="goPage('/buyer/cloudOrders')">发票申请</el-col>
              <el-col class="item footer-item" @click.native="goPage('/buyer/myResources')">资源管理</el-col>
            </el-col>
            <el-col class="link">
              <el-col class="item title">快速链接</el-col>
              <el-col class="item footer-item" @click.native="goPage('cloudManage')">云管系统</el-col>
              <el-col class="item footer-item" @click.native="goPage('productBPM')">eBPM审批管理</el-col>
              <el-col class="item footer-item" @click.native="goPage('productITSM')">eITSM工单管理</el-col>
            </el-col>
          </el-col>
        </el-col>
        <el-col class="horizontal-line"></el-col>
        <el-col class="beian">
          <el-col class="copyright">
            <el-col class="copyright-content">版权所有@深圳市伊登软件有限公司 2020 保留一切权利 <span class="footer-item">粤ICP备05081203号</span></el-col>
            <el-col class="policy">
              <el-col class="policy-content"><a href='/#/statement/privacyStatement1' target="_blank" style="text-decoration: none">法律声明</a></el-col>
              <el-col class="vertical-line"></el-col>
              <el-col class="policy-content"><a href='/#/statement/privacyStatement' target="_blank" style="text-decoration: none">隐私政策</a></el-col>
            </el-col>
          </el-col>
          <el-col class="condition">
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402000171" target="_blank">粤公网安备44030402000171号</a>
          </el-col>
        </el-col>
      </el-col>
    </el-col>
  </el-row>

</template>

<script>
export default {
  data(){
    return {

    }
  },
  mounted() {
  },
  methods: {
      goPage(path) {
        this.$router.push(path);
      },
      toDirect(url) {
          window.open(url);
      },
    goPrivacy(){
      console.log("31321313")
    }
  }
}
</script>

<style lang="less" scoped>
  .footer {
    height: 539px;
    background: #F3F3F3;
    box-shadow: 0px 3px 14px 1px rgba(0,0,0,0.1600);
    padding: 45px 0 37px 0;

    .footer-container {
      width: 1200px;
      float: unset;
      margin: auto;

      .eden-biz {
        margin-bottom: 41px;
        .biz-item:last-child {
          margin-right: 0;
        }

        .biz-item {
          width: auto;
          margin-right: 117px;

          .item-icon {
            margin-right: 20px;
            width: 35px;
            height: 35px;
          }

          .item-title {
            width: auto;
            font-size: 19px;
            color: #414141;
            line-height: 47px;
            font-weight: 400;
          }
        }
      }
      .horizontal-line {
        height: 1px;
        border-bottom: 1px solid #A4A4A4;

      }

      .about-eden {
        height: 186px;
        margin: 53px 0 46px 0;

        .contact {
          width: 113px;
          .contact-title {
            font-size: 16px;
            font-weight: 300;
            color: #414141;
          }

          .phone {
            margin-top: 25px;
          }

          .contact-wechat {
            margin-top: 21px;
          }

          .wechat {
            font-size: 14px;
            margin-top: 19px;
          }
        }

        .eden-product {
          width: auto;
          height: 186px;
          margin-left: 134px;

          .title {
            font-size: 16px !important;
            color: black !important;
            font-weight: bold !important;
            margin-bottom: 40px !important;

          }

          .item:last-child {
            margin-bottom: 0;
          }

          .item {
            margin-bottom: 26px;
            font-weight: 300;
            color: #414141;
            font-size: 14px;
          }

          .about {
            width: 75px;
          }

          .product {
            width: 82px;
            margin-left: 180px;
          }

          .account {
            width: 67px;
            margin-left: 180px;
          }

          .link {
            width: 95px;
            margin-left: 180px;
          }
        }

        .vertical-line {
          width: 1px;
          border-right: 1px solid #A4A4A4;
          height: 100%;
          margin-left: 60px;
        }
      }

      .beian {
        .copyright {
          margin-top: 27px;
          .copyright-content {
            float: left;
            width: auto;
            font-size: 14px;
            font-weight: 300;
            color: #414141;
          }

          .policy {
            float: right;
            width: auto;

            .policy-content {
              font-size: 14px;
              font-weight: 300;
              color: #414141;
              width: auto;
              a{
                font-size: 14px;
                font-weight: 300;
                color: #414141;
                width: auto;
              }
            }

            .vertical-line {
              width: 1px;
              height: 19px;
              border: 1px solid #A4A4A4;
              margin: 0 28px;
            }
          }
        }

        .condition {
          font-size: 14px;
          font-weight: 300;
          color: #414141;
          margin-top: 28px;
          a {
            text-decoration: none;
            color: #414141;
          }

          a:hover {
            color: #D60010;
          }
        }
      }
    }
      .footer-item {
          cursor: pointer;
      }
      .footer-item:hover {
          color: #f56e65 !important;
      }
  }
</style>
