import { render, staticRenderFns } from "./privacyStatement1.vue?vue&type=template&id=5a54bde5&scoped=true&"
import script from "./privacyStatement1.vue?vue&type=script&lang=js&"
export * from "./privacyStatement1.vue?vue&type=script&lang=js&"
import style0 from "./privacyStatement1.vue?vue&type=style&index=0&id=5a54bde5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a54bde5",
  null
  
)

export default component.exports