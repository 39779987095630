const saasProduct = {
    state:{
        saasProductId: ""
    },
    mutations:{
        setsaasProductId(state,info){
            state.saasProductId = info
        }

    },
};
export default saasProduct