import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from "@/store/userInfo";
import saasProduct from "@/store/saasProduct";
import common from "@/store/common";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        userInfo,
        saasProduct,
        common
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        reducer(data) {
            return {
                // 设置只储存state中的myData
                userInfo: data.userInfo,
                saasProductId: data.saasProductId,
                common: data.checkIndex
            }
        }
    })
    ]
})
export default store