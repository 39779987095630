<template>
  <el-row>
    <el-col class="header">
      <el-col class="header-container">
        <el-col class="header-logo" @click.native="goPage('homeIndex')">
          <img src="@/img/common/head/header-logo.png">
        </el-col>
<!--        <el-col class="header-menu">-->
<!--          <el-col class="menu-item">首页</el-col>-->
<!--          <el-col class="menu-item">云市场</el-col>-->
<!--          <el-col class="menu-item">云产品</el-col>-->
<!--          <el-col class="menu-item">简体中文</el-col>-->
<!--        </el-col>-->
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "head",
  data() {
    return {

    }
  },
  methods: {
    goPage(pagePath) {
      this.$router.push(pagePath);
    }
  }
}
</script>

<style lang="less" scoped>

  .header {
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 23px 1px rgba(0,0,0,0.0800);
    padding: 13px 50px;
    position: fixed;
    z-index: 1;
    top: 0;
    .header-container {

      .header-logo:hover {
        cursor: pointer;
      }

      .header-logo {
        width: auto;
      }
      //.header-menu {
      //  width: auto;
      //  float: right;
      //  height: 31px;
      //
      //  .menu-item:last-child {
      //    margin-right: 0;
      //  }
      //
      //  .menu-item {
      //    font-size: 14px;
      //    font-weight: 400;
      //    color: #414141;
      //    width: auto;
      //    margin-right: 50px;
      //    line-height: 31px;
      //  }
      //}
    }
  }

</style>
