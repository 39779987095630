<template>
  <div>
    <my-menu-bar v-if = "checkHeaderOrFooter(0)" @gomenu="openMenu" @openUser="openUser"></my-menu-bar>
    <login-header v-show="this.$route.name==='login' || this.$route.name==='register'"></login-header>
    <router-view ref="router" :key="key"></router-view>
    <el-row ref="rowmenu" style="display: none">
      <el-col class="footer">
        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="2" style="height: 1px"></el-col>
            <el-col :span="4" class="about-item" @click.native="goPage(1, '/home')">首页</el-col>
          </el-col>
        </el-col>
          <el-col class="about" :span="24">
            <el-col class="about-list-pro-left" :span="8">
              <el-col :span="6" style="height: 1px"></el-col>
              <el-col :span="10" class="about-item">产品</el-col>
            </el-col>
            <el-col class="about-list-pro-right" :span="16">
              <el-col :span="1" style="height: 1px"></el-col>
              <el-col :span="23" class="about-item-product-right" @click.native="goPage(2, '/DocSecurity')">文档安全管理系统</el-col>

              <el-col :span="1" style="height: 1px"></el-col>
              <el-col :span="23" class="about-item-product-right" @click.native="goPage(2, '/mailAttachment')">邮件大附件助手</el-col>

              <el-col :span="1" style="height: 1px"></el-col>
              <el-col :span="23" class="about-item-product-right" @click.native="goPage(2, '/assets')">资产管理系统</el-col>
            </el-col>
          </el-col>

        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="1" style="height: 1px"></el-col>
            <el-col :span="8" class="about-item" @click.native="goPage(3, '/SolutionIndex')">行业解决方法</el-col>
          </el-col>
        </el-col>

        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="2" style="height: 1px"></el-col>
            <el-col :span="4" class="about-item" @click.native="goPage(4, '/ServiceSupport')">服务支持</el-col>
          </el-col>
        </el-col>

        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="2" style="height: 1px"></el-col>
            <el-col :span="4" class="about-item" @click.native="goPage(5, '/AboutMe')">关于我们</el-col>
          </el-col>
        </el-col>
      </el-col>
    </el-row>

    <el-row ref="rowuser" style="display: none">

      <el-col class="footer" v-if="this.$store.state.userInfo.userInfo == ''">
        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="2" style="height: 1px"></el-col>
            <el-col :span="4" class="about-item" @click.native="login">登录/注册</el-col>
          </el-col>
        </el-col>

        <el-col style="height: 40vh;">
        </el-col>
      </el-col>

      <el-col class="footer" v-if="this.$store.state.userInfo.userInfo != ''">
        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="2" style="height: 1px"></el-col>
            <el-col :span="4" class="about-item" @click.native="goPage(6, '/membership')">我的订单</el-col>
          </el-col>
        </el-col>

        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="2" style="height: 1px"></el-col>
            <el-col :span="4" class="about-item" @click.native="goPage(6, '/updateUserInfo')">修改密码</el-col>
          </el-col>
        </el-col>

        <el-col class="about-nocontent">
          <el-col class="about-list">
            <el-col :span="2" style="height: 1px"></el-col>
            <el-col :span="4" class="about-item" @click.native="logout">退出登录</el-col>
          </el-col>
        </el-col>

        <el-col style="height: 15vh;">
        </el-col>
      </el-col>

    </el-row>
    <my-footer v-show = "checkHeaderOrFooter(1)" > </my-footer>
    <login-footer v-show="this.$route.name==='login' || this.$route.name==='register'"></login-footer>
  </div>
</template>

<script>
import MyMenuBar from './common/MenuBar.vue';
import MyFooter from './common/Footer.vue';
import MembershipBar from './common/MenberShipBar'
import loginHeader from './user/common/head'
import loginFooter from './user/common/footer'
export default {
  data() {
    return {
      isDisplay:true,
      userIsDisplay:false,
        //头部禁用页面
        disabledHeaderList: ['login', 'register', 'user', 'BuyerIndex', 'buyer', 'userAgreement','privacyStatement'],
        //底部禁用页面
        disabledFooterList: ['login', 'register', 'user', 'BuyerIndex', 'buyer', 'userAgreement','privacyStatement'],
    }
  },
  methods: {
      //判断头部和底部是否展示
      checkHeaderOrFooter(type) {
          let path = this.$route.name || '';
          //禁用地址
          let disabledList = this.disabledHeaderList;
          if(type === 1) {
              disabledList = this.disabledFooterList;
          }
          let ifShow = disabledList.findIndex((val) => {
              return path.indexOf(val) > -1;
          }) > -1;
          return  !ifShow;
      },
    openMenu() {
      if(this.isDisplay) {
        this.$refs.router.$el.style.display = 'none';
        this.$refs.rowmenu.$el.style.display = 'block';
        this.$refs.rowuser.$el.style.display = 'none';
        this.isDisplay = false;
        this.userIsDisplay = true;
      }else{
        this.$refs.router.$el.style.display = 'block';
        this.$refs.rowmenu.$el.style.display = 'none';
        this.$refs.rowuser.$el.style.display = 'none';
        this.isDisplay = true;
        this.userIsDisplay = true;
      }
    },
    openUser(){
      if(this.userIsDisplay) {
        this.$refs.router.$el.style.display = 'none';
        this.$refs.rowuser.$el.style.display = 'block';
        this.$refs.rowmenu.$el.style.display = 'none';
        this.userIsDisplay = false;
        this.isDisplay = true;
      }else{
        this.$refs.router.$el.style.display = 'block';
        this.$refs.rowuser.$el.style.display = 'none';
        this.$refs.rowmenu.$el.style.display = 'none';
        this.userIsDisplay = true;
        this.isDisplay = true;
      }
    },
    goPage(index, path) {
      this.$store.commit('setCheckIndex', index);
      // this.$store.state.common.checkIndex = index;
      this.$router.push({path: path});
    },
    login() {
      this.$router.push({path: '/login'})
    },
    logout() {
      this.$store.commit("logout")
      // this.setCookie('', '', -1);
      // if (this.$route.name ==='membership'){
      //   this.$router.push({path: '/home'})
      // }else {
      //   this.$router.go(0);
      // }
      this.$router.push({path: '/login'})
    }
  },
  components: {
    'my-menu-bar':MyMenuBar,
    'my-footer':MyFooter,
    'my-membership': MembershipBar,
    'login-header': loginHeader,
    'login-footer': loginFooter,
  },
  computed: {
    key() {
      return this.$route.name !== undefined? this.$route.name + +new Date(): this.$route + +new Date()
    }
  }
}
</script>

<style lang="less" scoped>

  /**
  * 此处根据设计图的尺寸修改原elementui组件的尺寸.
  **/
  /deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
  }

  /deep/ .el-input {
    height: 36px;
  }

  /deep/ .el-form-item__content {
    line-height: 36px;
  }

  /deep/ .el-form-item {
    margin-bottom: 20px;
  }

  /deep/ .el-form-item:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {

    /*html, body {
      background: #2D3142;
    }*/

    .banner {
      background: url("../img/home/home_banner.png") no-repeat top center;
      width: 99vw;
      height: 920px;


      .banner-des {
        font-size: 32px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
        line-height: 59px;
        padding-top: 45px;
      }

      .banner-title {
        font-size: 30px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 56px;
      }

      .banner-text {
        text-align: center;
      }

      .banner-carousel {
        text-align: center;

        .carousel {
          width: 100%;
          display: inline-block;

          .carousel-img {
            width: 100%;
            height: 450px;
          }

          .product-title {
            font-weight: 500;
            color: #FFFFFF;
            line-height: 56px;
            margin-bottom: 20px;
          }

          .product-des {
            width: 542px;
            height: 48px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            position: relative;
            bottom: 45px;
            left: 52px;
            display: none;
          }

          .banner-right-arrow {
            width: 27px;
            margin-left: 8px;
            padding-bottom: 3px;
          }

          .banner-btn {
            position: relative;
            bottom: 138px;
            width: 145px;
            height: 44px;
            background: #3764D7;
            border-radius: 20px;
            font-size: 20px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 20px;
            display: none;
          }

          .is-active .product-title {
            font-size: 30px;
          }

          .is-active .banner-btn {
            display: unset;
          }

          .is-active .product-des {
            display: unset;
          }
        }

        .switch-btn:hover {
          cursor: pointer;
        }

        .switch-btn {
          padding-top: 250px;
          height: 450px;
        }

        .left {
          text-align: right;
        }

        .right {
          text-align: left;
        }
      }

      .banner-notice {
        margin-top: 72px;

        .notice {
          height: 42px;
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.5);
          border-radius: 27px;
          position: relative;

          .notice-icon {

            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 27px;
            height: 42px;
            background-image: url("../img/common/ic_tongzhi.svg");
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: center;
            margin-right: 1px;
          }

          .text-container {
            position: absolute;
            top: 0px;
            right: 7%;
          }
        }
      }
    }

    .partner {
      margin-top: 60px;
      margin-bottom: 65px;

      .partner-list {
        margin-top: 48px;
        margin-bottom: 89px;
      }
    }
    .footer {
      width: 100%;
      background-color: #2D3142;

      .about-nocontent{
        height: 100px;
        border-bottom: 1px solid #FFFFFF;
        //padding-left: 606px;
        padding-top: 15px;

        .about-item {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          //margin-left: 72px;
          text-align: center;
        }
        .about-item-product-right{
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #b1b1b1;
          line-height: 24px;
          text-align: left;

        }

        .about-list:last-child {
          margin-top: 24px;
        }
        .about-list-pro-left{
          margin-top: 24px;
        }
        .about-list-pro-right{

        }

        .about-item:hover {
          color: #3764D7;
          cursor: pointer;
        }

        .about-item:first-child {
          margin-left: 0;
        }
      }
      .about {
        height: 125px;
        border-bottom: 1px solid #FFFFFF;
        //padding-left: 606px;
        padding-top: 30px;

        .about-item {
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 24px;
          //margin-left: 72px;
          text-align: center;
        }
        .about-item-product-right{
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          color: #b1b1b1;
          line-height: 24px;
          text-align: left;

        }

        .about-list:last-child {
          margin-top: 24px;
        }
        .about-list-pro-left{
          margin-top: 24px;
        }
        .about-list-pro-right{

        }

        .about-item:hover {
          color: #3764D7;
          cursor: pointer;
        }

        .about-item:first-child {
          margin-left: 0;
        }
      }
    }
  }
</style>
