<template>
  <div>
    <el-row>
      <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="0">
        <div class="box2 flex-col justify-center">
          <span class="txt3">会员中心</span>
        </div>
      </el-col>
      <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="0">
        <el-row>
          <el-col :span="24">
            <div class="box3 flex-col align-center">
              <div class="block1 flex-row">
                <img
                    class="label2"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngceca0327e7f332af1121b972627d1378ef28e586d0915b5ff7bd107323b7da9b"
                />
                <span class="word6">{{ userInfo.userName }}，您好</span>
                <span class="word7">企业名称：{{ userInfo.companyName }}</span>
                <span class="info3"></span>
              </div>
              <template v-if="this.$route.name ==='membership'">
                <el-button type="success" plain class="info4" @click.native="updatePassword" >
                  <span style="margin-left:  -10px">修改密码</span>
                </el-button>

                <el-button type="success" plain class="word8" @click.native="logout">
                  <span style="margin-left:  -9px">退出登录</span>
                </el-button>
              </template>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :sm="0" class="eployInfo" :span="22">
        <el-col :span="1" :sm="0" class="mobile-product_img">
          <img src="../../img/membership/menberIcon.png" style="width: 18px;height: 18px ;margin: 12px 0px 35px 16px;">
        </el-col>
        <el-col :span="15" class="user-name">{{ userInfo.userName }}，您好</el-col>
        <el-col :span="15" class="company-name">企业名称：{{ userInfo.companyName }}</el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";

export default {
  name: "MenberShip",
  data() {
    return {
      userInfo: {
        userName: this.$store.state.userInfo.userInfo.userAuthAccount,
        companyName: this.$store.state.userInfo.userInfo.companyName,
      },
    }
  },
  methods:{
    updatePassword(){
      this.$router.push({path: '/updateUserInfo'})
    },
    logout() {
      this.$store.commit("logout")
      this.setCookie('', '', -1);
      this.$router.push({path: '/home'});
    },
    setCookie(portId, psw, exdays) {
      // Encrypt，加密账号密码
      var cipherPortId = CryptoJS.AES.encrypt(
          portId + '',
          'secretkey123'
      ).toString()
      var cipherPsw = CryptoJS.AES.encrypt(psw + '', 'secretkey123').toString()
      console.log(cipherPortId + '/' + cipherPsw)// 打印一下看看有没有加密成功

      var exdate = new Date() // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      // 字符串拼接cookie，为什么这里用了==，因为加密后的字符串也有个=号，影响下面getcookie的字符串切割，你也可以使用更炫酷的符号。
      window.document.cookie =
          'currentPortId' +
          '==' +
          cipherPortId +
          ';path=/;expires=' +
          exdate.toGMTString()
      window.document.cookie =
          'password' +
          '==' +
          cipherPsw +
          ';path=/;expires=' +
          exdate.toGMTString()
    },
  },
}
</script>

<style scoped>
.company-name {
  height: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #414141;
  line-height: 18px;
  text-align: left;
  margin: -34px 8px 10px 43px;
}
.user-name {
  height: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #414141;
  line-height: 18px;
  margin: 12px 0px 35px 24px;
}
.eployInfo {
  width: 95.66667%;
  height: 68px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 8px 8px 0px 8px;
}
.el-button {
  line-height: 0;
}

.el-button--success.is-plain:focus, .el-button--success.is-plain:hover {
  background: rgb(52, 100, 224);
  border-color: rgb(52, 100, 224);
  color: #FFF;

}

.el-button--success.is-plain:hover .txt5 {
  color: #FFFFFF;
}

.el-button--success.is-plain {
  color: #3764d7;
  border-color: #9dadd7;
  background: #ffffff;
}

.box2 {
  box-sizing: border-box;
  height: 160px;
  /*background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng06255154c1aecb63cfd57233bf8c785be703fd88d1aebec83e677067af72f965) 100% no-repeat;*/
  background: url(../../img/membership/membership.svg) 100% no-repeat;
  display: flex;
  flex-direction: column;
  padding-left: 360px;
  /*width: 1920px;*/
  justify-content: center;
}

.txt3 {
  box-sizing: border-box;
  width: 160px;
  height: 56px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 40.00px;
  white-space: nowrap;
  line-height: 56.00px;
  text-align: left;
}

.box3 {
  box-sizing: border-box;
  z-index: 79;
  height: 85px;
  background-color: rgba(247, 247, 247, 1);
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  /*width: 1920px;*/
  position: relative;
  align-items: center;
}

.block1 {
  box-sizing: border-box;
  width: 1200px;
  height: 32px;
  flex-direction: row;
  display: flex;
}

.label2 {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin-top: 1px;
}

.word6 {
  box-sizing: border-box;
  width: 119px;
  height: 25px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 18.00px;
  white-space: nowrap;
  line-height: 25.00px;
  text-align: left;
  margin: 4px 0 0 16px;
}

.word7 {
  box-sizing: border-box;
  width: 182px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;
  white-space: nowrap;
  line-height: 20.00px;
  text-align: left;
  margin: 6px 0 0 392px;

}

.info3 {
  box-sizing: border-box;
  width: 155px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;
  white-space: nowrap;
  line-height: 20.00px;
  text-align: left;
  margin: 6px 0 0 56px;
}

.layer1 {
  box-sizing: border-box;
  width: 80px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
  margin-left: 58px;
  display: flex;
  flex-direction: column;
}

.layer2 {
  box-sizing: border-box;
  width: 80px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
  margin-left: 32px;
  display: flex;
  flex-direction: column;
}

.el-button {
  line-height: 0;
}

.el-button--success.is-plain:focus, .el-button--success.is-plain:hover {
  background: rgb(52, 100, 224);
  border-color: rgb(52, 100, 224);
  color: #FFF;

}

.el-button--success.is-plain:hover .txt5 {
  color: #FFFFFF;
}


.el-button--success.is-plain {
  background: #ffffff;
}

.info4 {
  /*margin-left: -8px;*/
  background: white;
  box-sizing: border-box;
  z-index: 98;
  color: #FFF;
  border: 1px solid rgba(180, 196, 237, 1);
  border-radius: 8px;
  position: absolute;
  left: 1368px;
  top: 26px;
  width: 80px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgb(52, 100, 224);
  font-size: 14.00px;

}

.word8 {
  background: white;
  box-sizing: border-box;
  z-index: 95;
  border: 1px solid rgba(180, 196, 237, 1);
  border-radius: 8px;
  position: absolute;
  left: 1470px;
  top: 26px;
  width: 80px;
  height: 32px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 14.00px;
  white-space: nowrap;
}
</style>
