<template>
  <el-row>
    <el-col class="header">
      <el-col class="header-container">
        <el-col class="header-logo" @click.native="goPage('homeIndex')"></el-col>
        <el-col class="header-menu">
          <el-col class="menu-list">
<!--            <el-col :class="{'menu-item': true}" @click.native="goPage('homeIndex')">首页</el-col>-->
<!--            <el-col :class="{'menu-item': true}" @click.native="goPage('softwareList')">软件商城</el-col>-->
<!--            <el-col :class="{'menu-item': true}">云市场</el-col>-->
<!--            <el-col :class="{'menu-item': true}">云产品</el-col>-->
<!--            <el-col :class="{'menu-item': true}" @click.native="goPage('home')">解决方案</el-col>-->
<!--            <el-col :class="{'menu-item': true}" @click.native="goPage('informationIndex')">资讯动态</el-col>-->
              <el-col
                   v-for="(item,idx) in pageList"
                   :key="idx"
                   :class="{'menu-item': true, 'menu-active': $store.state.common.checkIndex == idx + 1}"
                   @click.native="goPage(item.path, idx + 1)"
                   @mouseenter.native="mouseover(item.name)"
                   @mouseleave.native="mouseout(item.name)"
              >{{ item.name }}
                <el-collapse-transition v-if="item.name == '云市场'">
                  <div v-show="show3" class="transition-box" >
                    <div class="container">
                      <el-col v-for="(item1,idx) in data" :key="idx" class="cloud" @click.native="openProductDetail(item1.marketId)">
                        <div class="item-name" >{{item1.name}}</div>
                        <div class="text">
                          <div class="item-line">{{item1.profile}}</div>
                        </div>
                      </el-col>
                    </div>
                  </div>
                </el-collapse-transition>
<!--                <el-collapse-transition v-if="item.name == '云产品'">-->
<!--                  <div v-show="show2" class="transition-box-2" >-->
<!--                    <div class="container">-->
<!--                      <el-col class="tag">-->
<!--                          <el-col v-for="(tag,index) in tags" :key="index" style="line-height: 35px" @click.native="changeTag(index)" >-->
<!--                            <span :class="{'tag-name':true,'tag-name-active':tag.ifActive}">{{ tag.name }}</span>-->
<!--                          </el-col>-->
<!--                      </el-col>-->
<!--                      <el-col class="content">-->
<!--                        <el-col class="content-item" v-for="(content,idx) in tagList" :key="idx">-->
<!--                          <el-col class="title">{{content.title}}</el-col>-->
<!--                          <el-col class="text" v-for="(text,idx) in content.list" :key="idx" @click.native="redirectHuawei(text.url)">{{text.name}}</el-col>-->
<!--                        </el-col>-->
<!--                      </el-col>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                </el-collapse-transition>-->
              </el-col>
            <el-col class="menu-item menu-img" style="visibility:hidden">
              <img src="@/img/common/head/chazhao.svg">
            </el-col>
            <el-col class="head-btn" v-if="this.$store.state.userInfo.userInfo == ''">
              <el-button class="btn-login" @click.native="goPage('login')">登录</el-button>
              <el-button class="btn-register" @click.native="goPage('register')">注册</el-button>
            </el-col>
            <el-col class="user-info-header" v-if="this.$store.state.userInfo.userInfo != ''" >
              <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" class="header" @click.native="goPage('/buyer/BuyerIndex')"></el-avatar>
              <el-dropdown>
                <el-col>
                  <span class="user-name">{{userName}}</span><i class="el-icon-arrow-down el-icon--right"></i>
                </el-col>
                <el-dropdown-menu slot="dropdown" class="drop-style">
                  <el-dropdown-item  class="el-dropdown-item" @click.native="goPage('/buyer/BuyerIndex')">买家中心</el-dropdown-item>
                  <el-dropdown-item  class="el-dropdown-item" @click.native="goPage('/buyer/AddWork')">个人信息</el-dropdown-item>
                  <el-dropdown-item  class="el-dropdown-item" @click.native="goPage('/buyer/editAccount')">修改密码</el-dropdown-item>
                  <el-dropdown-item  class="el-dropdown-item-end" @click.native="goPage('logout')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>

import marketApi from "../../api/cloudSoftMarket/marketApi";
import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      userName: 'ed666',
        pageList: [
            {
                name: '首页',
                path: 'homeIndex',
                ifActive: false,
            },
            {
                name: '软件商城',
                path: 'softwareList',
                ifActive: false,
            },
            {
                name: '云市场',
                path: '',
                ifActive: false,
            },
            // {
            //     name: '云产品',
            //     path: '',
            //     ifActive: false,
            // },
            {
                name: '解决方案',
                path: 'home',
                ifActive: false,
            },
            {
                name: '资讯动态',
                path: 'informationIndex',
                ifActive: false,
            },
        ],
        window: window,
        checkPage: '',
      show3: false,
      show2: false,
      data: [],
      tags:[
        {
          name: '推荐',
          ifActive:true,
        },
        {
          name: '基础服务',
          ifActive: false
        },
        {
          name: 'EI企业智能',
          ifActive: false
        },
        {
          name: '开发者',
          ifActive: false
        },
        {
          name: '安全',
          ifActive: false
        },
        {
          name: '企业应用',
          ifActive: false
        },
        {
          name: 'LOT物联网',
          ifActive: false
        },
      ],
      tagList: [],
      tagSet:[
          {data: [
              {
                title: '为您推荐',
                list: [
                  {name: '弹性云服务器 ECS',url: 'https://console.huaweicloud.com/ecm/?region=cn-north-4#/ecs/createVm'},
                  {name: '华为云WeLink',url: 'https ://console.huaweicloud.com/welink/?region=cn-north-4#/welink/order'},
                  {name: '云硬盘 EVS',url: 'https://console.huaweicloud.com/ecm/?region=cn-north-4#/evs/createvolume'},
                  {name: '对象存储服务 OBS',url: 'https://storage.huaweicloud.com/obs/?region=cn-north-4&locale=zh-cn#/obs/buy"'},
                  {name: '云数据库 MySQL',url: 'https://console.huaweicloud.com/rds/?Engine=mysql&region=cn-north-4#/rds/createIns/'},
                  {name: '内容分发网络 CDN',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4#/commonbuy/cdnCloud'},
                  {name: 'AI开发平台ModelArts',url: 'https://console.huaweicloud.com/modelarts/?region=cn-north-4#/dashboard/purchasePackageWindow'},
                  {name: '域名注册服务',url: 'https://console.huaweicloud.com/webmobile/?region=cn-north-4#/cloudsite/base-threeinone/order'},
                  {name: '软件开发平台 DevCloud',url: 'https://www.huaweicloud.com/pricing.html#/devcloud'},
                  {name: '云容器引擎 CCE',url: 'https://console.huaweicloud.com/cce2.0/?region=cn-north-4#/app/resource/cluster/createCluster'},
                  {name: '云数据库 GaussDB(for MySQL)',url: 'https://console.huaweicloud.com/gaussdb/?region=cn-east-3#/gaussdb/createIns/'},
                ]
              },
              {
                title: '全新发布',
                list: [
                  {name: '云备份',url: 'https://console.huaweicloud.com/cbr/?region=cn-north-4&locale=zh-cn#/cbr/createVault/csbs'},
                  {name: '云手机',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4#/applyBeta?serviceCodeP=cph'},
                  {name: '容器安全服务',url: 'https://console.huaweicloud.com/cgs/?region=cn-north-4&locale=zh-cn#/cgs/swrAuthorization'},
                  {name: '存储容灾服务 SDRS',url: 'https://console.huaweicloud.com/sdrs/?region=cn-north-4&locale=zh-cn#/sdrs/manager/sdrsList'},
                  {name: '分布式消息服务 Kafka',url: 'https://console.huaweicloud.com/dms/?region=cn-north-4&engine=kafka#/queue/newCreateKafka'},
                  {name: '数据可视化 DLV',url: 'https://console.huaweicloud.com/dlv/?region=cn-north-4&locale=zh-cn#/purchase'},
                  {name: '云速建站',url: 'https://console.huaweicloud.com/webmobile/?region=cn-north-4#/cloudsite/promotion-fiveinone/order'},
                  {name: '短视频',url: 'https://console.huaweicloud.com/vod/?region=cn-north-4#/shortVideoSdkOrder'},
                ]
              },
              {
                title: '公测产品',
                list: [
                  {name: '主机迁移服务',url: 'https://console.huaweicloud.com/sms/?region=cn-south-1#/sms/overview'},
                ]
              },
            ],},
          {data: [
              {
                title: '计算',
                list: [
                  {name: '弹性云服务器 ECS',url: 'https://console.huaweicloud.com/ecm/?region=cn-north-4#/ecs/createVm'},
                  {name: 'GPU加速云服务器',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3&az=cn-east-3a&flavor=pi2.2xlarge.4#/ecs/createVm'},
                  {name: 'FPGA加速云服务器',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3&az=cn-east-2b&flavor=fp1.2xlarge.11#/ecs/createVm'},
                  {name: '裸金属服务器 BMS',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3&locale=zh-cn#/bms/createBMS'},
                  {name: '云手机',url: 'https://account.huaweicloud.com/usercenter/?region=cn-east-3#/applyBeta?serviceCodeP=cph'},
                  {name: '专属主机',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3&locale=zh-cn#/deh/createDeh'},
                  {name: '弹性伸缩 AS',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3#/as/createGroupNew'},
                  {name: '镜像服务',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3#/ims/createImageUnite'},
                  {name: '函数工作流 FunctionGraph',url: 'https://console.huaweicloud.com/functiongraph/?region=cn-east-3#/serverless/dashboard'},
                ]
              },
              {
                title: '存储',
                list: [
                  {name: '对象存储服务 OBS',url: 'https://storage.huaweicloud.com/obs/?region=cn-east-3&locale=zh-cn#/obs/buy'},
                  {name: '云硬盘 EVS',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3#/evs/createvolume'},
                  {name: '云备份',url: 'https://console.huaweicloud.com/cbr/?region=cn-east-3&locale=zh-cn#/cbr/createVault/csbs'},
                  {name: '专属分布式存储服务',url: 'https://console.huaweicloud.com/ecm/?region=cn-east-3&locale=zh-cn#/dss/create'},
                  {name: '云硬盘备份',url: 'https://www.huaweicloud.com/pricing.html#/cbr'},
                  {name: '云服务器备份',url: 'https://www.huaweicloud.com/pricing.html#/cbr'},
                  {name: '存储容灾服务 SDRS',url: 'https://console.huaweicloud.com/sdrs/?region=cn-north-4&locale=zh-cn#/sdrs/manager/sdrsList'},
                  {name: '弹性文件服务',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4&locale=zh-cn#/buyservice/sfsCloud'},
                  {name: '数据快递服务',url: 'https://console.huaweicloud.com/des/?region=cn-north-4&locale=zh-cn#/des/createOrder'},
                  {name: '专属企业存储服务 DESS',url: 'https://console.huaweicloud.com/dess/?region=cn-north-4#/dess/manager/nonsupportRegion'},
                  {name: '云存储网关 CSG',url: 'https://console.huaweicloud.com/csg/?region=cn-north-4#/csg/CsgIndex'},
                ]
              },
              {
                title: '网络',
                list: [
                  {name: '虚拟私有云 VPC',url: 'https://console.huaweicloud.com/vpc/?region=cn-north-4#/vpcs'},
                  {name: '弹性负载均衡 ELB',url: 'https://console.huaweicloud.com/vpc/?region=cn-north-4#/elbs/createEnhanceElb'},
                  {name: 'NAT网关 NAT',url: 'https://console.huaweicloud.com/vpc/?region=cn-north-4#/nat/list'},
                  {name: '弹性公网IP EIP',url: 'https://console.huaweicloud.com/vpc/?region=cn-north-4#/eips/createEIP'},
                  {name: '云专线',url: 'https://console.huaweicloud.com/cc/?region=cn-north-4#/dc/physicalDlineTe'},
                  {name: '虚拟专用网络 VPN',url: 'https://console.huaweicloud.com/cc/?locale=zh-cn&region=cn-north-4#/vpn/vpngws'},
                  {name: '云连接 CC',url: 'https://console.huaweicloud.com/cc/?region=cn-north-4#/cc/cns'},
                  {name: '云解析服务 DNS',url: 'https://console.huaweicloud.com/dns/?region=cn-north-4#/dns/dashboard'},
                  {name: 'VPC终端节点',url: 'https://console.huaweicloud.com/vpc/?locale=zh-cn&region=cn-north-4#/vpcep/vpceps'},
                ]
              },
              {
                title: '数据库',
                list: [
                  {name: '数据库',url: 'https://console.huaweicloud.com/rds/?region=cn-north-4&Engine=mysql#/rds/createIns/'},
                  {name: '云数据库 GaussDB(for MySQL)',url: 'https://console.huaweicloud.com/gaussdb/?region=cn-east-3#/gaussdb/createIns/'},
                  {name: '云数据库 MySQL',url: 'https://console.huaweicloud.com/rds/?region=cn-east-3&Engine=mysql#/rds/createIns/'},
                  {name: '云数据库 GaussDB(openGauss)',url: 'https://console.huaweicloud.com/ticket/?region=cn-north-4&locale=zh-cn#/ticketindex/createIndex'},
                  {name: '云数据库 PostgreSQL',url: 'https://console.huaweicloud.com/rds/?region=cn-east-3&engine=postgresql#/rds/createIns/'},
                  {name: '云数据库 SQL Server',url: 'https://console.huaweicloud.com/rds/?region=cn-east-3&engine=sqlserver#/rds/createIns/'},
                  {name: '文档数据库服务 DDS',url: 'https://console.huaweicloud.com/dds/?region=cn-east-3#/dds/createIns/'},
                  {name: '分布式数据库中间件 DDM',url: 'https://console.huaweicloud.com/ddm/?region=cn-south-1&locale=zh-cn#/ddm/createVm/'},
                  {name: '云数据库 GeminiDB',url: 'https://console.huaweicloud.com/geminidb/?locale=zh-cn&region=cn-south-1#/instance/create'},
                  {name: '数据复制服务 DRS',url: 'https://www.huaweicloud.com/pricing.html#/drs'},
                  {name: '数据管理服务 DAS',url: 'https://console.huaweicloud.com/das/?region=cn-south-1#/das/connections/free'},
                ]
              },
              {
                title: '容器服务',
                list: [
                  {name: '云容器引擎 CCE',url: 'https://www.huaweicloud.com/product/cce.html'},
                  {name: '云容器实例 CCI',url: 'https://console.huaweicloud.com/cci/?region=cn-north-4#/agency'},
                  {name: '容器镜像服务 SWR',url: 'https://console.huaweicloud.com/swr/?region=cn-east-3#/app/dashboard'},
                  {name: '应用编排服务 AOS',url: 'https://console.huaweicloud.com/aos/?region=cn-east-3#/app/dashboard'},
                ]
              },
              {
                title: '智能云提速',
                list: [
                  {name: '内容分发网络 CDN',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4#/commonbuy/cdnCloud'},
                ]
              },
              {
                title: '视频',
                list: [
                  {name: '视频直播',url: 'https://console.huaweicloud.com/live/?region=cn-north-4#/pushSdkOrder'},
                  {name: '短视频',url: 'https://console.huaweicloud.com/vod/?region=cn-north-4#/shortVideoSdkOrder'},
                  {name: '视频点播',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4#/buyservice/commonCloud?pkgCode=vod'},
                  {name: '媒体处理',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4#/buyservice/commonCloud?pkgCode=mpc'},
                ]
              },
              {
                title: '应用中间件',
                list: [
                  {name: '应用管理与运维平台 ServiceStage',url: 'https://console.huaweicloud.com/servicestage/?region=cn-south-1#/serviceAgency'},
                  {name: '微服务引擎 CSE',url: 'https://console.huaweicloud.com/servicestage/?region=cn-south-1&package=basic&new=true#/serviceAgency'},
                  {name: '分布式缓存服务 Redis',url: 'https://console.huaweicloud.com/dcs/?region=cn-south-1#/dcs/createCache'},
                  {name: '分布式缓存服务 Memcached',url: 'https://console.huaweicloud.com/dcs/?region=cn-south-1#/dcs/createCache'},
                  {name: '分布式消息服务 DMS',url: 'https://console.huaweicloud.com/dcs/?region=cn-south-1#/dcs/createCache'},
                  {name: '分布式消息服务 Kafka',url: 'https://console.huaweicloud.com/dms/?region=cn-south-1&engine=kafka#/queue/newCreateKafka'},
                  {name: '分布式消息队列 RabbitMQ',url: 'https://console.huaweicloud.com/dms/?region=cn-south-1&engine=rabbitMQ#/queue/newCreateRabbitMQ'},
                  {name: 'API网关 APIG',url: 'https://console.huaweicloud.com/apig/?region=cn-south-1#/apig/manager/overview'},
                  {name: '云性能测试服务 CPTS',url: 'https://console.huaweicloud.com/cpts/?region=cn-south-1#/home'},
                ]
              },
              {
                title: '管理与监管',
                list: [
                  {name: '云监控服务',url: 'https://console.huaweicloud.com/ces/?region=cn-north-4#/overview/dashboard2'},
                  {name: '应用运维管理 AOM',url: 'https://console.huaweicloud.com/aom/?region=cn-north-4#/aom/ams/summary'},
                  {name: '应用性能管理 APM',url: 'https://console.huaweicloud.com/apm/?region=cn-north-4#/apm/atps/dashboard'},
                  {name: '云日志服务 LTS',url: 'https://console.huaweicloud.com/lts/?region=cn-north-4#/cts/manager/groups'},
                  {name: '统一身份认证服务 IAM',url: 'https://console.huaweicloud.com/iam/?region=cn-east-3#/iam/users'},
                  {name: '消息通知服务',url: 'https://cn-east-3-console.huaweicloud.com/smn/?agencyId=08e8a1f06780f46e1f35c01a7d0ae6e8&region=cn-east-3#/smn/manager/dashboard'},
                  {name: '云审计服务 CTS',url: 'https://console.huaweicloud.com/cts/?region=cn-north-4#/cts/manager/settingList?from=1001'},
                ]
              },
              {
                title: '域名与网站',
                list: [
                  {name: '域名注册服务',url: 'https://console.huaweicloud.com/webmobile/?region=cn-north-4#/cloudsite/base-threeinone/order'},
                  {name: '云解析服务 DNS',url: 'https://console.huaweicloud.com/dns/?region=cn-north-1#/dns/dashboard'},
                  {name: '云速建站',url: 'https://console.huaweicloud.com/webmobile/?region=cn-north-4#/cloudsite/promotion-fiveinone/order'},
                  {name: 'SSL证书管理',url: 'https://console.huaweicloud.com/scs/?region=cn-north-4&locale=zh-cn#/scs/purchase'},
                ]
              },
              {
                title: '迁移',
                list: [
                  {name: '主机迁移服务',url: 'https://console.huaweicloud.com/sms/?region=cn-south-1#/sms/overview'},
                  {name: '对象存储迁移服务',url: 'https://console.huaweicloud.com/oms/?region=cn-south-1#/maasMainPage/seeMigrationTasks/'},
                  {name: '云数据迁移 CDM',url: 'https://console.huaweicloud.com/cdm/?region=cn-south-1&locale=zh-cn#/cdm/createInstance'},
                  {name: '数据复制服务 DRS',url: 'https://www.huaweicloud.com/pricing.html#/drs'},
                ]
              },
            ],},
          {data: [
              {
                title: 'EI基础平台',
                list: [
                  {name: 'AI开发平台ModelArts',url: 'https://console.huaweicloud.com/modelarts/?region=cn-north-4#/dashboard/purchasePackageWindow'},
                  {name: '华为HiLens',url: 'https://console.huaweicloud.com/hilens/?region=cn-north-4&locale=zh-cn#/hilens/skillMarket/hilensKitPurchase'},
                  {name: '图引擎服务 GES',url: 'https://console.huaweicloud.com/ges/?region=cn-north-4&locale=zh-cn#/createInstance'},
                  {name: '视频接入服务 VIS',url: 'https://console.huaweicloud.com/vis/?region=cn-north-4#/vis/subscription'},
                ]
              },
              {
                title: '图像识别 Image',
                list: [
                  {name: '图像识别 Image',url: 'https://console.huaweicloud.com/image_recognition/?region=cn-north-4&locale=zh-cn#/irs/services/management'},
                  {name: '图像标签',url: 'https://console.huaweicloud.com/image_recognition/?region=cn-north-4&locale=zh-cn#/irs/services/imageTagging'},
                  {name: '名人识别',url: 'https://console.huaweicloud.com/image_recognition/?region=cn-north-4&locale=zh-cn#/irs/services/celebrityRecognition'},
                ]
              },
              {
                title: '语音交互服务',
                list: [
                  {name: '定制语音识别',url: 'https://console.huaweicloud.com/sis/?region=cn-north-4&locale=zh-cn#/sis/lasr'},
                  {name: '定制语音合成',url: 'https://console.huaweicloud.com/sis/?region=cn-north-4&locale=zh-cn#/sis/stts'},
                  {name: '实时语音转写',url: 'https://console.huaweicloud.com/sis/?region=cn-north-4&locale=zh-cn#/sis/rasr'},
                  {name: '语音识别',url: 'https://console.huaweicloud.com/sis/?region=cn-north-4&locale=zh-cn#/sis/lasr'},
                  {name: '语音合成',url: 'https://console.huaweicloud.com/sis/?region=cn-north-4&locale=zh-cn#/sis/lasr'},
                ]
              },
              {
                title: '对话机器人服务',
                list: [
                  {name: '智能问答机器人',url: 'https://console.huaweicloud.com/cbs/?region=cn-north-4#/cbs/management/qabot/home'},
                  {name: '任务型对话机器人',url: 'https://console.huaweicloud.com/cbs/?region=cn-north-4#/cbs/management/taskBot'},
                  {name: '智能质检',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4#/applyBeta?serviceCodeP=cbs_qi'},
                ]
              },
              {
                title: '人脸与人体识别',
                list: [
                  {name: '人脸识别服务 FRSe',url: 'https://console.huaweicloud.com/frs/?region=cn-north-4#/frs/home'},
                ]
              },
              {
                title: '自然语言处理',
                list: [
                  {name: '自然语言处理基础',url: 'https://console.huaweicloud.com/nlp/?region=cn-north-4#/nlp/nlpf'},
                  {name: '语言理解',url: 'https://console.huaweicloud.com/nlp/?region=cn-north-4#/nlp/nlpf'},
                  {name: '语言生成',url: 'https://console.huaweicloud.com/nlp/?region=cn-north-4#/nlp/nlpf'},
                  {name: '机器翻译',url: 'https://console.huaweicloud.com/nlp/?region=cn-north-4#/nlp/nlpf'},
                  {name: '知识图谱',url: 'https://console.huaweicloud.com/nlp-kg/?region=cn-north-4#/nlp-kg/management/mineGraph'},
                ]
              },
              {
                title: '图像搜索服务',
                list: [
                  {name: '图像搜索 ImageSearch',url: 'https://console.huaweicloud.com/imagesearch/?region=cn-north-4&locale=zh-cn#/imagesearch/open'},
                ]
              },
              {
                title: '文字识别 OCR',
                list: [
                  {name: '文字识别 OCR',url: 'https://console.huaweicloud.com/ocr/?region=cn-north-4&locale=zh-cn#/ocr/management/serviceList/auto-classification'},
                  {name: '通用类',url: 'https://console.huaweicloud.com/ocr/?region=cn-north-4#/ocr/prepay'},
                  {name: '证件类',url: 'https://console.huaweicloud.com/ocr/?region=cn-north-4#/ocr/prepay'},
                  {name: '票据类',url: 'https://console.huaweicloud.com/ocr/?region=cn-north-4#/ocr/prepay'},
                  {name: '行业类',url: 'https://console.huaweicloud.com/ocr/?region=cn-north-4#/ocr/prepay'},
                  {name: '定制模板',url: 'https://console.huaweicloud.com/ocr/?region=cn-north-4#/ocr/prepay'}
                ]
              },
              {
                title: '大数据搜索与分析',
                list: [
                  {name: '云搜索服务 CSS',url: 'https://console.huaweicloud.com/elasticsearch/?region=cn-north-4#/es/management/dashboard'},
                ]
              },
              {
                title: '视频技术',
                list: [
                  {name: '视频内容分析 VCR',url: 'https://console.huaweicloud.com/iva/?region=cn-north-4&serviceCode=vcr#/iva/agency?vas=false'},
                  {name: '视频编辑',url: 'https://console.huaweicloud.com/iva/?region=cn-north-4&serviceCode=vcp#/iva/agency?vas=false'},
                ]
              },
              {
                title: '内容审核 Moderation',
                list: [
                  {name: '内容审核 Moderation',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4&locale=zh-cn#/buyservice/commonCloud?pkgCode=moderation'},
                  {name: '内容审核-文本',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4&locale=zh-cn#/buyservice/commonCloud?pkgCode=moderation'},
                  {name: '内容审核-图像',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4&locale=zh-cn#/buyservice/commonCloud?pkgCode=moderation'},
                  {name: '内容审核-视频',url: 'https://account.huaweicloud.com/usercenter/?region=cn-north-4&locale=zh-cn#/buyservice/commonCloud?pkgCode=vcm'},
                ]
              },
              {
                title: '大数据应用',
                list: [
                  {name: '推荐系统 RES',url: 'https://console.huaweicloud.com/res/?region=cn-north-4#/main/management/overview'},
                ]
              },
              {
                title: '大数据计算',
                list: [
                  {name: 'MapReduce服务',url: 'https://console.huaweicloud.com/mrs/?region=cn-north-4#/clusterCreate'},
                  {name: '实时流计算服务 CS',url: 'https://console.huaweicloud.com/cs/?region=cn-north-4&locale=zh-cn#/apply/from/cs.dashboard'},
                  {name: '数据湖探索 DLI',url: 'https://console.huaweicloud.com/dli/?region=cn-north-4&locale=zh-cn#/main/resource/queues'},
                  {name: '数据仓库服务 DWS',url: 'https://console.huaweicloud.com/dws/?locale=zh-cn&region=cn-north-4#/dws/prepay'},
                  {name: '表格存储服务 CloudTable',url: 'https://console.huaweicloud.com/cloudtable/?region=cn-north-4&locale=zh-cn#/cloudtable/management/list'},
                ]
              },
              {
                title: '大数据治理与开发',
                list: [
                  {name: '智能数据湖运营平台 DAYU',url: 'https://console.huaweicloud.com/dayu/?region=cn-north-4#/purchase/professional?isTrail=false'},
                  {name: '数据接入服务 DIS',url: 'https://console.huaweicloud.com/dis/?region=cn-north-4#/create/createInstanceNew'},
                  {name: '云数据迁移 CDM',url: 'https://console.huaweicloud.com/cdm/?region=cn-north-4&locale=zh-cn#/cdm/createInstance'},
                ]
              },
              {
                title: '数据可视化',
                list: [
                  {name: '数据可视化 DLV',url: 'https://console.huaweicloud.com/dlv/?region=cn-north-4#/trialOrder'},
                ]
              },
            ],},
          {data: [
              {
                title: '软件开发平台 DevCloud',
                list: [
                  {name: '软件开发平台 DevCloud',url: 'https://www.huaweicloud.com/pricing.html#/devcloud'},
                  {name: '项目管理 ProjectMan',url: 'https://www.huaweicloud.com/pricing.html#/devcloud'},
                  {name: '代码托管 CodeHub',url: 'https://www.huaweicloud.com/pricing.html#/CodeHub'},
                  {name: '流水线 CloudPipeline',url: 'https://devcloud.huaweicloud.com/nonsupportRegion?errorRegionName=%E5%8D%8E%E5%8C%97-%E5%8C%97%E4%BA%AC%E4%B8%80&errorService=pipeline'},
                  {name: '代码检查 CodeCheck',url: 'https://devcloud.huaweicloud.com/nonsupportRegion?errorRegionName=%E5%8D%8E%E5%8C%97-%E5%8C%97%E4%BA%AC%E4%B8%80&errorService=codecheck'},
                  {name: '编译构建 CloudBuild',url: 'https://www.huaweicloud.com/pricing.html#/devcloud'},
                  {name: '部署 CloudDeploy',url: 'https://devcloud.huaweicloud.com/nonsupportRegion?errorRegionName=%E5%8D%8E%E5%8C%97-%E5%8C%97%E4%BA%AC%E4%B8%80&errorService=deployman'},
                  {name: '云测 CloudTest',url: 'https://www.huaweicloud.com/pricing.html#/TestMan'},
                  {name: '发布 CloudRelease',url: 'https://www.huaweicloud.com/pricing.html#/ReleaseMan'},
                  {name: '移动应用测试 MobileAPPTest',url: 'https://console.huaweicloud.com/devcloud/?region=cn-north-4#/mobileapptest/list'},
                  {name: 'CloudIDE',url: 'https://devcloud.cn-north-4.huaweicloud.com/cloudide/home'},
                  {name: 'Classroom',url: 'https://classroom.devcloud.huaweicloud.com/home'},
                  {name: '华为开源镜像站 Mirrors',url: 'https://mirrors.huaweicloud.com/'},
                ]
              },
              {
                title: '开发者平台',
                list: [
                  {name: '应用管理与运维平台 ServiceStage',url: 'https://www.huaweicloud.com/pricing.html?tab=detail#/servicestage'},
                  {name: '微服务引擎 CSE',url: 'https://console.huaweicloud.com/servicestage/?region=cn-north-4&package=basic&new=true#/serviceAgency'},
                  {name: '云容器引擎 CCE',url: 'https://console.huaweicloud.com/cce2.0/?region=cn-north-4#/app/authorize?lastPage=https:%2F%2Fconsole.huaweicloud.com%2Fcce2.0%2F%3Fregion%3Dcn-north-4%23%2Fapp%2Fdashboard'},
                  {name: '函数工作流 FunctionGraph',url: 'https://console.huaweicloud.com/functiongraph/?region=cn-north-4#/serverless/dashboard'},
                ]
              },
              {
                title: '运维',
                list: [
                  {name: '应用运维管理 AOM',url: 'https://console.huaweicloud.com/aom/?region=cn-north-1#/aom/ams/summary'},
                  {name: '应用性能管理 APM',url: 'https://console.huaweicloud.com/apm/?region=cn-north-4#/apm/atps/dashboard'},
                  {name: '云性能测试服务 CPTS',url: 'https://console.huaweicloud.com/cpts/?region=cn-north-4#/home'},
                ]
              },
              {
                title: '云API开发',
                list: [
                  {name: 'API网关 APIG',url: 'https://console.huaweicloud.com/apig/?region=cn-north-4#/apig/manager/overview'},
                  {name: 'API Explorer',url: 'https://apiexplorer.developer.huaweicloud.com/apiexplorer/overview'},
                ]
              },
              {
                title: '仓库',
                list: [
                  {name: '容器镜像服务 SWR',url: 'https://console.huaweicloud.com/swr/?region=cn-north-4#/app/dashboard'},
                ]
              },

            ],},
          {data: [
              {
                title: '网络安全',
                list: [
                  {name: 'DDoS高防',url: 'https://console.huaweicloud.com/cad/?region=cn-north-4&locale=zh-cn#/cad/purchase/mainland'},
                  {name: 'Anti-DDoS流量清洗',url: 'https://console.huaweicloud.com/antiddos/?region=cn-north-4&locale=zh-cn#/antiddos/ddos/ipList'},
                ]
              },
              {
                title: '计算安全',
                list: [
                  {name: '企业主机安全',url: 'https://console.huaweicloud.com/hss/?region=cn-north-4&version=premium#/hss/purchase'},
                  {name: '容器安全服务',url: 'https://console.huaweicloud.com/cgs/?region=cn-north-4&locale=zh-cn#/cgs/swrAuthorization'},
                ]
              },
              {
                title: '应用安全',
                list: [
                  {name: '漏洞扫描服务',url: 'https://console.huaweicloud.com/vss/?region=cn-north-4#/vss/purchase'},
                  {name: 'Web应用防火墙 WAF',url: 'https://console.huaweicloud.com/waf/?region=cn-north-4#/waf/purchase'},
                ]
              },
              {
                title: '数据安全',
                list: [
                  {name: '数据库安全服务 DBSS',url: 'https://www.huaweicloud.com/pricing.html#/dbss'},
                  {name: '数据加密服务',url: 'https://console.huaweicloud.com/dew/?region=cn-north-4&locale=zh-cn#/hsm/hsmPurchase'},
                  {name: '云证书管理服务 CCM',url: 'https://console.huaweicloud.com/pca/?region=cn-north-4#/pca/publicTest'},
                ]
              },
              {
                title: '安全管理',
                list: [
                  {name: '安全专家服务',url: 'https://console.huaweicloud.com/sas/?region=cn-north-4#/sas/purchase?ticket_id=ServiceApplication&status=selectService&service_type=wsa'},
                  {name: '态势感知',url: 'https://console.huaweicloud.com/ssa/?region=cn-north-4&locale=zh-cn#/ssa/purchaseSsa'},
                  {name: 'SSL证书管理',url: 'https://console.huaweicloud.com/scs/?region=cn-north-4&locale=zh-cn#/scs/purchase'},
                  {name: '云堡垒机',url: 'https://console.huaweicloud.com/cbh/?region=cn-north-4&locale=zh-cn#/cbh/purchase'},
                ]
              },
            ],},
          {data: [
              {
                title: '企业协同',
                list: [
                  {name: '华为云WeLink',url: 'https://console.huaweicloud.com/welink/?region=cn-north-4#/welink/order'},
                  {name: '会议',url: 'https://meetingshow.huaweicloud.com/saleConsult.html'},
                ]
              },
              {
                title: '应用平台',
                list: [
                  {name: '应用管理与运维平台 ServiceStage',url: 'https://console.huaweicloud.com/servicestage/?region=cn-north-4#/serviceAgency'},
                ]
              },
              {
                title: '云通信',
                list: [
                  {name: '隐私保护通话',url: 'https://console.huaweicloud.com/privatenumber/?region=cn-north-4#/management/overview'},
                  {name: '语音通话',url: 'https://console.huaweicloud.com/voicecall/?region=cn-north-4#/commercial/overview'},
                  {name: '消息&短信',url: 'https://console.huaweicloud.com/message/?region=cn-north-4&subType=cn_sms#/opening'},
                ]
              },
              {
                title: '企业网络',
                list: [
                  {name: '云管理网络',url: 'https://console.huaweicloud.com/cmn/?region=cn-north-4#/cmn/onDemand/orderService'},
                  {name: 'SD-WAN云服务',url: 'https://console.huaweicloud.com/sdwan/?region=cn-north-4#/nonsupportRegion'},
                  {name: '企业网关服务',url: 'https://console.huaweicloud.com/sdwanvgws/?region=cn-north-4#/vgw'},
                  {name: '企业网关连接服务',url: 'https://console.huaweicloud.com/sdwanipsecvpn/?region=cn-north-4#/vpn'},
                  {name: '黑名单服务',url: 'https://console.huaweicloud.com/sdwanblacklist/?region=cn-north-4#/blacklist'},
                  {name: '白名单服务',url: 'https://console.huaweicloud.com/sdwanwhitelist/?region=cn-north-4#/whitelist'},
                  {name: '网站过滤服务',url: 'https://console.huaweicloud.com/sdwanurlfilter/?region=cn-north-4#/urlFilter'},
                  {name: '视频加速服务',url: 'https://console.huaweicloud.com/sdwanfecAd/?region=cn-north-4#/fecframe'},
                  {name: '传输加速服务',url: 'https://console.huaweicloud.com/sdwantcpAd/?region=cn-north-4#/tcpframe'},
                  {name: '边缘数据中心管理 EDCM',url: 'https://console.huaweicloud.com/edcm/?region=cn-north-4#/ncoObt'},
                ]
              },
              {
                title: '应用与数据集成',
                list: [
                  {name: '应用与数据集成平台 ROMA',url: 'https://console.huaweicloud.com/roma/?region=cn-north-4#/roma/overview'},
                  {name: 'API网关 APIG',url: 'https://console.huaweicloud.com/apig/?region=cn-north-4#/apig/manager/overview'},
                  {name: '分布式消息服务 Kafka',url: 'https://console.huaweicloud.com/dms/?region=cn-north-4&engine=kafka#/queue/newCreateKafka'}
                ]
              },
              {
                title: '区块链',
                list: [
                  {name: '区块链服务 BCS',url: 'https://console.huaweicloud.com/bcs/?region=cn-north-4#/app/dashboard'},
                ]
              },
            ],},
          {data: [
              {
                title: '智能硬件',
                list: [
                  {name: '企业智慧屏 IdeaHub',url: 'https://www.huaweicloud.com/product/ideahub.html'},
                ]
              },
              {
                title: '行业物联网服务',
                list: [
                  {name: '道路感知服务 RPS',url: 'https://console.huaweicloud.com/v2x/?region=cn-north-4#/ng2app/open-test'},
                  {name: '园区物联网服务 IoTC',url: 'https://console.huaweicloud.com/smartcampus/?region=cn-north-4#/OCSmartCampus/service/applyOCSmartCampus'},
                ]
              },
              {
                title: '物联网云服务',
                list: [
                  {name: '设备接入 IoTDA',url: 'https://console.huaweicloud.com/iotdm/?region=cn-north-4&locale=zh-cn#/dm-portal/instance/buy?version=enterprise&tps=1000&online=10w&duration=2_1'},
                  {name: '设备管理 IoTDM',url: 'https://console.huaweicloud.com/iotdm/?region=cn-north-4#/dm-portal/home'},
                  {name: '设备发放 IoTDP',url: 'https://console.huaweicloud.com/iot/?region=cn-north-4#/iotdmp/spMenu/applyIodps'},
                  {name: '全球SIM联接 GSL',url: 'https://console.huaweicloud.com/iot/?region=cn-north-4#/iotdmp/buyDataPlanForChannel'},
                  {name: 'IoT开发者服务 IoTStudio',url: 'https://console.huaweicloud.com/iotstudio/?region=cn-north-4#/ocs-solution'},
                  {name: 'IoT数据分析 IoTA',url: 'https://console.huaweicloud.com/iotanalytics/?region=cn-north-4&from=product#/ng2app/public/trial'},
                ]
              },
              {
                title: '边缘计算',
                list: [
                  {name: 'IoT边缘 IoTEdge',url: 'https://console.huaweicloud.com/iot/?region=cn-north-4#/iotdmp/spMenu/edge/edgeNode'},
                  {name: '智能边缘平台 IEF',url: 'https://console.huaweicloud.com/ief2.0/?region=cn-north-4#/app/apply'},
                ]
              },

            ],},
      ],
    };
  },
  created() {
    if (this.$store.state.userInfo.userInfo.name != null){
      this.userName = this.$store.state.userInfo.userInfo.name;
    }
  },
    mounted() {
      this.ifNow();
      this.tagList = this.tagList = this.tagSet[0].data
      marketApi.getMarketListByState().then((res) => {
        this.data = res.data
      })
    },
    methods: {
      changeTag(index) {
        this.tags.forEach(item =>{
          item.ifActive = false
        })
        this.tags[index].ifActive = true
        this.tagList = this.tagSet[index].data
      },
      ifNow() {
          let href = this.window.location.href;
          if(href.indexOf("?") > -1) {
              href = href.substring(href.indexOf('#') + 2, href.indexOf("?"));
          }else {
              href = href.substring(href.indexOf('#') + 2, href.length);
          }

          if(this.checkPage.length > 0) {
              href = this.checkPage;
          }
          this.pageList.forEach(item => {
              if(item.path == href) {
                  item.ifActive = true;
              }else if (item.name == '云市场' && href == 'cloudMarket'){
                item.ifActive = true;
              }
              else {
                  item.ifActive = false
              }
          });
      },
      // 跳转到华为
      redirectHuawei(url){
        window.open("https://auth.huaweicloud.com/authui/saml/login?xAccountType=EDENSOFT_2013_IDP&isFirstLogin=false&service=" + encodeURIComponent(url));
      },
    goPage(pagePath, idx) {
        if (pagePath== ''){
          return;
        }
      // 退出登录并返回首页
      if (pagePath == 'logout'){
        this.$store.commit('logout');
        // 清除cookie
        window.sessionStorage.clear()
        // 存储token
        localStorage.clear()
        // 存储用户信息
        this.$store.commit('setUserInfo','');
        this.setCookie('', '', -1);
        this.$router.push('/');
        this.$store.commit('setCheckIndex', 1);
        return;
      }
      this.checkPage = pagePath;
      //this.ifNow();
      this.$store.commit('setCheckIndex', idx);
      this.$router.push(pagePath);
    },
    setCookie(portId, psw, exdays) {
      // Encrypt，加密账号密码
      var cipherPortId = CryptoJS.AES.encrypt(
          portId + '',
          'secretkey1234567'
      ).toString()
      var cipherPsw = CryptoJS.AES.encrypt(psw + '', 'secretkey1234567').toString()

      var exdate = new Date() // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      // 字符串拼接cookie，为什么这里用了==，因为加密后的字符串也有个=号，影响下面getcookie的字符串切割，你也可以使用更炫酷的符号。
      window.document.cookie =
          'currentPortId' +
          '==' +
          cipherPortId +
          ';path=/;expires=' +
          exdate.toGMTString()
      window.document.cookie =
          'password' +
          '==' +
          cipherPsw +
          ';path=/;expires=' +
          exdate.toGMTString()
    },
    mouseover(name) {
        if(name == '云市场') {
          this.show3 = true
        }else if(name == '云产品') {
          this.show3 = false
          this.show2 = true
        }
    },
    mouseout(name) {
        if(name == '云市场'){
          this.show3 = false
        }else if (name == '云产品') {
          this.show2 = false
        }
    },
    openProductDetail(marketId) {
      this.checkPage = 'cloudMarket';
      //this.ifNow();
      this.$store.commit('setCheckIndex', 3);
      this.$router.push({name: `cloudMarket`,query:{productId: marketId, orderType: 1}});
    },

  },

};
</script>
<style scoped>
.drop-style{
  width: 148px!important;
  /*height: 190px!important;*/
  text-align: center!important;
  border-radius: 15px!important;
  overflow: hidden!important;
  z-index: 2010!important;
}
.el-dropdown-item{
  /*width: 60px!important;*/
  margin: 0 22px!important;
  font-size: 14px!important;
  color: #101D3A!important;
  line-height: 45px!important;
  border-bottom: #b7b7b7 1px solid!important;
}
.el-dropdown-item-end{
  /*width: 60px!important;*/
  margin: 0 22px!important;
  font-size: 14px!important;
  color: #101D3A!important;
  line-height: 45px!important;
}
.el-dropdown-item-end:hover{
  /*width: 60px!important;*/
  background: unset!important;
  color: black!important;
  font-weight: bolder!important;
}
.el-dropdown-item:hover{
  /*width: 60px!important;*/
  background: unset!important;
  color: black!important;
  font-weight: bolder!important;
}
</style>
<style lang="less" scoped>
  @media screen and (max-width: 1200px){
    .header-container {
      width: 910px !important;
    }
  }

  .header {
    height: 88px;
    background: #FFFFFF;
    .transition-box {
      z-index: 99999999;
      width: 100%;
      position: absolute;
      height: 490px;
      border-radius: 4px;
      background-color: #f7f7f7;
      color: #fff;
      left: 0px;
      .container {
        width: 1400px;
        height: 100%;
        margin: 0px auto;
        float: unset;
      }
    }
    .transition-box-2 {
      z-index: 99999999;
      width: 100%;
      position: absolute;
      height: 100vh;
      border-radius: 4px;
      background-color: #f7f7f7;
      color: #fff;
      left: 0px;
      .container {
        width: 1400px;
        height: 100%;
        margin: 0px auto;
        float: unset;
        .tag {
          width: 250px;
          height: 600px;
          margin-top: 30px;
          margin-left: 110px;
          border-right:1px solid #3d3d3d;
          .tag-name {
            font-size: 14px;
            line-height: 30px;
            color: #424242;
            margin-left: 50px;
          }
          .tag-name-active {
            font-size: 16px;
            //font-width: bold;
            border:none;
            border-bottom: 2px solid #D60010
          }
          .tag-name:hover {
            font-size: 16px;
          }
        }
        .content {
          width: 1000px;
          height: 600px;
          margin-top: 30px;
          margin-left: 30px;
          overflow-y: auto;
          .content-item {
            width: 200px;
            margin-left: 40px;
            min-height: 415px;
            border-bottom: 0.5px solid rgba(210,216,222,0.8);
            margin-bottom: 20px;
            .title {
              color: #000000;
              line-height: 30px;
              font-weight: bolder;
            }
            .text {
              color: #5b5b5b;
              line-height: 30px;
            }
            .text:hover {
              color: #D60010;
            }
          }
        }
        .content::-webkit-scrollbar {
          width: 4px;
        }
        .content::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #646464;  //上层块颜色
        }
        .content::-webkit-scrollbar-thumb:hover {
          background: #d9d9d9;  //上层块颜色
        }
        .content::-webkit-scrollbar-track {
          border-radius: 10px;
          background: #bab9b9;   //底层背景块颜色
        }
      }
    }
    .header-container {
      width: 1200px;
      float: unset;
      margin: auto;

      .header-logo {
        float: left;
        background: url("../../img/common/head/header-logo.png") no-repeat top center;
        width: 188px;
        height: 31px;
        margin-top: 28px;
          cursor: pointer;
      }

      .header-menu {
        width: auto;
        float: right;
        .menu-list {

          .menu-item:hover {
            cursor: pointer;
            color: #D60010;
          }
            .menu-active {
                color: #D60010 !important;
            }

          .menu-item {
            font-size: 14px;
            font-weight: bold;
            color: #000000;
            line-height: 88px;
            width: auto;
            margin-right: 60px;
            .cloud {
              margin-top: 20px;
              width: 300px;
              height: 150px;
              overflow: hidden;
              margin-left: 140px;
              //position: relative;
              .item-name {
                margin-top: 15px;
                line-height: 0;
                //height: 50px;
                color: #1B1B1B;
              }
              .item-name:hover{
                color: #ff6c00;
              }
              .item-line {
                margin-top: 30px;
                float: unset;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                overflow: hidden;
                line-height: 20px;
                color: #000000;
                opacity: 0.5;
              }
            }
          }

          .head-btn {
            width: auto;
            line-height: 99px;

            .btn-login {
              width: 71px;
              height: 31px;
              background: #FFFFFF;
              border: 1px solid #D60010;
              font-size: 14px;
              color: #D60010;
            }

            .btn-register {
              width: 71px;
              height: 31px;
              background: #D60010;
              font-size: 14px;
              color: #FFFFFF;
            }

            .el-button {
              padding: 8px 20px;
            }
          }

          .menu-img {
            line-height: 99px;
            margin-left: 52px;
          }

          .user-info-header {
            width: auto;
            /* height: 40px; */
            font-size: 16px;
            /* margin-left: 35px; */
            color: #FFFFFF;
            margin-top: 28px;
            display: inline;
            cursor: pointer;
          .header {
              width: 30px;
              height: 30px;
              line-height: 39px;
              margin-right: 10px;
              border: 1px solid black;
          }
          .user-name {
            color: #000000;
            font-size: 16px;
            font-width: 350;
            float: left;
            width: 65px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          /deep/.el-dropdown {
                font-size: 16px;
                margin-top: 3px;
                float: right;
            //margin-top: -11px;

          }
        }

      }
    }
  }
}

</style>
