const userInfo = {
    state:{
        userInfo: ""
    },
    mutations:{
        setUserInfo(state,info){
            state.userInfo = info;
        },
        logout(state) {
            state.userInfo = "";
            // 清空缓存
            sessionStorage.removeItem('vuex')
            localStorage.removeItem('accessToken')
        }
    },

};
export default userInfo
