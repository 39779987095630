import {get} from "@/utils/axios"

const getMarketListByState = p => get("/api-common/market/getMarketListByState", p);

//查询亮点、操作指南、客户案例
const getMarketFull = p => get("/api-common/market/getMarketFull", p);

export default {
    getMarketListByState,
    getMarketFull
}
