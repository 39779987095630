import Vue from "vue";
import VueRouter from 'vue-router'
import privacyStatement from "../views/statement/privacyStatement";
import lawStatement from "../views/statement/lawStatement";
import privacyStatement1 from "../views/statement/privacyStatement1";

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err);
}

// 页面路由
export default new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/homeIndex'
        },
        {
            path: '/',
            meta: { title: '自述文件' },
            component: () => import('@/views/home/home.vue'),
            children: [
                {
                    path: '/home',
                    component: () => import('@/views/home/home.vue'),
                    meta: { title: '解决方案' }
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/user/login.vue'),
            meta:{
                title: '登录',
                showNav: true
            }
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/user/registry.vue'),
            meta: { title: '注册' }
        },
        // 伊登隐私政策
        {
          path: '/statement/privacyStatement',
          name: 'privacyStatement',
          component: () => import('@/views/statement/privacyStatement.vue')
        },
        {
            path: '/statement/lawStatement',
            name: 'lawStatement',
            component: () => import('@/views/statement/lawStatement.vue')
        },
        {
            path: '/statement/privacyStatement1',
            name: 'privacyStatement1',
            component: () => import('@/views/statement/privacyStatement1.vue')
        },

        // 伊登云商城用户许可协议
        {
            path: '/statement/userAgreement',
            name: 'userAgreement',
            component: () => import('@/views/statement/userAgreement.vue')
        },
        {
            path: '/goodsDescription',
            name: 'goodsDescription',
            component: () => import('@/views/goods/GoodsDescription.vue')
        },
        // 产品详情
        {
            path: '/ProductDescription',
            name: 'ProductDescription',
            component: () => import('@/views/product/ProductDescription.vue'),
            meta: { title: '商品详情' }
        },
        // 云市场--产品详情
        {
            path: '/cloudMarket',
            name: 'cloudMarket',
            component: () => import('@/views/cloudMarket/cloudMarket.vue'),
            meta: { title: '商品详情' }
        },
        {
            path: '/goodsRenewal',
            name: 'goodsRenewal',
            component: () => import('@/views/goods/goodsRenewal.vue')
        },
        {
            path: '/goodsExpansion',
            name: 'goodsExpansion',
            component: () => import('@/views/goods/goodsExpansion.vue')
        },
        {
            path: '/goodsUpgrade',
            name: 'goodsUpgrade',
            component: () => import('@/views/goods/goodsUpgrade.vue')
        },
        {
            path: '/DocSecurity',
            name: 'DocSecurity',
            component: () => import('@/views/product/cloudDoc.vue'),
            meta: { title: '文档安全管理系统' }
        },
        {
            path: '/SolutionIndex',
            name: 'SolutionIndex',
            component: () => import('@/views/solution/SolutionIndex.vue')
        },
        {
            path: '/MedicalSolution',
            name: 'SolutionIndex',
            component: () => import('@/views/solution/medicalSolution.vue')
        },
        {
            path: '/MakingSolution',
            name: 'SolutionIndex',
            component: () => import('@/views/solution/makingSolution.vue')
        },
        {
            path: '/FinanceSolution',
            name: 'SolutionIndex',
            component: () => import('@/views/solution/financeSolution.vue')
        },
        {
            path: '/BuildingSolution',
            name: 'SolutionIndex',
            component: () => import('@/views/solution/buildingSolution.vue')
        },
        {
            path: '/OfficeSolution',
            name: 'SolutionIndex',
            component: () => import('@/views/solution/officeSolution.vue')
        },
        {
            path: '/LogisticsSolution',
            name: 'SolutionIndex',
            component: () => import('@/views/solution/logisticsSolution.vue')
        },
        {
            path: '/TryApply',
            name: 'TryApply',
            component: () => import('@/views/tryapply/TryApply.vue')
        },
        {
            path: '/ServiceSupport',
            name: 'ServiceSupport',
            component: () => import('@/views/servicesupport/ServiceSupport.vue')
        },
        {
            path: '/AboutMe',
            name: 'AboutMe',
            component: () => import('@/views/aboutus/about.vue')
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('@/views/member/user.vue')
        },
        {
            path: '/mailAttachment',
            name: 'mail',
            component: () => import('@/views/product/mailAttachment')
        },
        //多云管理平台
        {
            path: '/cloudManage',
            name: 'cloudManage',
            component: () => import('@/views/product/cloudManage'),
            meta: { title: '多云管理平台' }

        },
        //bpm审批管理
        {
            path: '/productBPM',
            name: 'productBPM',
            component: () => import('@/views/product/productBPM'),
            meta: { title: 'eBPM审批管理系统' }
        },
        //ITSM工单管理
        {
            path: '/productITSM',
            name: 'productITSM',
            component: () => import('@/views/product/productITSM'),
            meta: { title: 'eITSM工单管理系统' }
        },
        {
            path: '/assets',
            name: 'assets',
            component: () => import('@/views/product/assets')
        },
        {
            path: '/orderList',
            name: '/orderList',
            component: () => import('@/views/order/list')
        },
        // 会员中心首页
        {
            path: '/membership',
            name: 'membership',
            component: () => import('@/views/member/index.vue')
        },
        // 修改用户信息
        {
            path: '/updateUserInfo',
            name: 'updateUserInfo',
            component: () => import('@/views/member/updateUserInfo.vue')
        },
        {
            path: "/informationIndex",
            name: "informationIndex",
            component: () => import('@/views/information/infoIndex.vue'),
            meta: { title: '咨询动态' }
        },
        {
            path: "/homeIndex",
            name: "homeIndex",
            component: () => import('@/views/home/HomeIndex.vue'),
            meta: { title: '提供云计算服务' }
        },
        // 支付页面
        {
            path: "/pay",
            name: "pay",
            component: () => import('@/views/pay/pay.vue'),
            meta: { title: '支付' }
        },
        // 软件列表
        {
            path: '/softwareList',
            name: 'SoftwareList',
            component: () => import('@/views/softwaremall/SoftwareList.vue'),
            meta: { title: '软件商城' }
        },
        {
            path: "/shopping",
            name: 'shopping',
            component: () => import("@/views/shopping/shoppingInfo")
        },
        //购物车
        {
            path: "/shoppingCart",
            name: "shoppingCart",
            component: () => import('@/views/shoppingCart/shoppingCart.vue')
        },
        {
            path: "/realtime",
            name: "realtime",
            component: () => import('@/views/realtime/realTime.vue'),
            meta: { title: '咨询动态' },
        },
        // 左侧导航栏
        {
            path: "/buyerLiftMenu",
            name: "buyerLiftMenu",
            redirect:"/buyer/BuyerIndex",
            component: () => import('@/views/common/BuyerLiftMenu.vue'),
            meta: { title: '买家中心' },
            children: [
                // 导航栏的路由放到这里

                // 下面是列子
                {
                     path: "/buyer/BuyerIndex",
                     name: '/buyer/BuyerIndex',
                     component: () => import('@/views/buyer/home/BuyerIndex.vue'),
                    meta: { title: '买家中心' },
                 },
                {
                    path: "/buyer/workOrder",
                    name: '/buyer/workOrder',
                    component: () => import('@/views/buyer/workOrder/MyWork.vue'),
                    meta: { title: '买家中心' },
                },

                {
                    path: "/buyer/AddWork",
                    name: '/buyer/AddWork',
                    component: () => import('@/views/buyer/workOrder/AddWork.vue'),
                    meta: { title: '买家中心' },
                },
                {
                    path: "/buyer/cloudOrders",
                    name: '/buyer/cloudOrders',
                    component: () => import('@/views/cloudOrders/cloudOrders.vue'),
                    meta: { title: '买家中心' },
                },
                {
                    path: "/buyer/orderInfo",
                    name: '/buyer/orderInfo',
                    component: () => import('@/views/cloudOrders/orderInfo.vue'),
                    meta: { title: '买家中心' },
                },
                // 买家中心--软件商城订单
				{
					path: '/buyer/SoftwareMallOrder',
					name: '/buyer/SoftwareMallOrder',
					component: () => import('@/views/softwaremall/SoftwareMallOrder.vue'),
                    meta: { title: '买家中心' },
				},
                // 退货管理
                {
                    path: "/returnOrder",
                    name: '/buyer/returnOrder',
                    component: () => import('@/views/order/returnOrder.vue'),
                    meta: { title: '买家中心' },
                },
                // 退货管理
                {
                    path: "/buyer/afterSale",
                    name: '/buyer/afterSale',
                    component: () => import('@/views/buyer/afterSale.vue'),
                    meta: { title: '买家中心' },
                },
                // 交易管理 - 退订管理
                {
                    path: "/buyer/unsubscribe",
                    name: '/buyer/unsubscribe',
                    component: () => import('@/views/buyer/transaction/Unsubscribe.vue'),
                    meta: { title: '买家中心' },
				},
                {
                    path: '/buyer/coupon',
                    name: '/buyer/coupon',
                    component: () => import("@/views/coupon/coupon"),
                    meta: { title: '买家中心' },
                },
                // 交易管理 - 发票管理
                {
                    path: "/buyer/invoice",
                    name: '/buyer/invoice',
                    component: () => import('@/views/buyer/transaction/invoice.vue'),
                    meta: { title: '买家中心' },
                },
                // 系统管理 - 地址管理
                {
                    path: "/buyer/address",
                    name: '/buyer/address',
                    component: () => import('@/views/buyer/system/address.vue'),
                    meta: { title: '买家中心' },
                },
                {
                    path: "/buyer/account",
                    name: '/buyer/account',
                    component: () => import('@/views/buyer/system/accountMana.vue'),
                    meta: { title: '买家中心' },
                },
                // 我的资源
                {
                    path: "/buyer/myResources",
                    name: '/buyer/myRresources',
                    component: () => import('@/views/buyer/resources/Resources.vue'),
                    meta: { title: '买家中心' },
                },
                // 我的关注
                {
                    path: "/buyer/myFocus",
                    name: '/buyer/myFocus',
                    component: () => import('@/views/buyer/focus/Focus.vue'),
                    meta: { title: '买家中心' },
                },
                // 交易管理-合同管理
                {
                    path: "/buyer/contract",
                    name: '/buyer/contract',
                    component: () => import('@/views/buyer/transaction/contract.vue'),
                    meta: { title: '买家中心' },
                },
                // 软件管理 - 修改密码
                {
                    path: "/buyer/editAccount",
                    name: '/buyer/editAccount',
                    component: () => import('@/views/buyer/editAccount/EditAccount.vue'),
                    meta: { title: '买家中心' },
                },
                // 通知 - 消息中心
                {
                    path: "/buyer/messageCenter",
                    name: "/buyer/messageCenter",
                    component: () => import('@/views/buyer/message/messageCenter.vue'),
                    meta: { title: '买家中心' },
                },
            ]
        }
    ]
})



