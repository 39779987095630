<template>
  <div id="app">
    <my-index/>
  </div>
</template>

<script>
import MyIndex from './views/Index.vue'
export default {
  name: 'App',
  components: {
    'my-index':MyIndex
  },
  created() {
    // this.saveStoreValue();
  },
  methods: {
    saveStoreValue() {
      // 防止浏览器刷新之后全局变量的值重置。
      if(window.localStorage.getItem('store')) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(window.localStorage.getItem('store'))));
      }
      window.addEventListener('beforeunload', () => {
        window.localStorage.setItem('store', JSON.stringify(this.$store.state));
      });
    }
  }
}
</script>

<style>
  #app {
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 0;
  }

  body {
    margin: 0 auto;
    background: #F7F7F7;;
  }

  .title-bold {
      font-size: 24px !important;
      font-weight: bold !important;
      color: #3764D7 !important;
  }

  .title-default {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #3764D7 !important;
  }

  @media screen and (max-width: 575px) {
    html, body {
      width: 100%;
      height: 100%;
      position: relative;
      /*background: #2D3142;*/
    }
  }
</style>
