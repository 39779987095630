<template>
  <el-row>
    <el-col class="footer">
      <el-col class="footer-container">
        <el-col class="copyright item">版权所有@深圳市伊登软件有限公司 2020 保留一切权利</el-col>
<!--        <el-col class="policy">-->
<!--          <el-col class="item">法律声明</el-col>-->
<!--          <el-col class="vertical-line"></el-col>-->
<!--          <el-col class="item">隐私政策</el-col>-->
<!--        </el-col>-->
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "footer"
}
</script>

<style lang="less" scoped>
  .footer {
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 23px 1px rgba(0,0,0,0.0800);
    position:fixed;
    z-index: 1;
    bottom: 0;
    .footer-container {
      text-align: center;
      padding: 21px 0;
      .copyright {

      }

      .item {
        font-size: 14px;
        font-weight: 400;
        color: #414141;
      }
      .policy {
        float: right;
        width: auto;

        .policy-content {
          font-size: 14px;
          font-weight: 300;
          color: #414141;
          width: auto;
        }

        .vertical-line {
          width: 1px;
          height: 19px;
          border: 1px solid #A4A4A4;
          margin: 0 28px;
        }
      }
    }
  }
</style>